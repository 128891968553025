import { PayPerGameResponseBody } from '../types/general';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_PLACEMENT_PRICING: RequestMetadata = {
	path: '/data/pricing/boosting/placement/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type PlacementPayPerGameResponseBody = PayPerGameResponseBody;

export const getPlacementPricing = async (): Promise<RequestResponse<PlacementPayPerGameResponseBody>> => {
	return await getRequest<undefined, PlacementPayPerGameResponseBody>({ ...GET_PLACEMENT_PRICING });
};

export default getPlacementPricing;
