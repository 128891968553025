import React from 'react';
import { useDispatch } from 'react-redux';
import { setCookieVisibility } from '../../redux/actions/generalActions';
import { ThunkGlobalDispatch } from '../../redux/root';
import { setCookie } from '../../utils/cookies';

const CookiesBar = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	const hideCookie = () => {
		setCookie('ck_07001aea8517663c', 'y', 30);
		thunkDispatch(setCookieVisibility(false));
	};

	return (
		<aside className="cookies-bar">
			<span className="cookies-bar__info">
				We use 🍪 (cookies), to ensure the best website experience. [&nbsp;
				<a href="https://us.norton.com/internetsecurity-privacy-what-are-cookies.html" target="_blank">
					Find out more
				</a>
				&nbsp;]
			</span>
			<button onClick={hideCookie} className="cookies-bar__button">
				I understand
			</button>
		</aside>
	);
};

export default CookiesBar;
