import React, { useEffect, useState } from 'react';
import AnimatedComponent from '../../animation/AnimatedComponent';
import clsx from 'clsx';

interface ILeagueProps {
	name: string;
	imgSrc: string;
	handleLeagueSelection: () => void;
	isActive?: boolean;
}

const League: React.FC<ILeagueProps> = ({ name, imgSrc, handleLeagueSelection, isActive }) => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div onClick={() => handleLeagueSelection()} className={clsx({ active: isActive }, 'league')}>
			<AnimatedComponent in={isLoaded}>
				<img src={imgSrc} className="league__logo" />
				<p className="league__name">{name}</p>
			</AnimatedComponent>
		</div>
	);
};

export default League;
