import React from 'react';
import Step from './Step';
const first = require('../../../images/landing/first.png');
const second = require('../../../images/landing/second.png');
const third = require('../../../images/landing/third.png');

const StepsList: React.FC = () => {
	return (
		<div className="steps-list container">
			<Step
				imgUrl={first}
				stepNumber={1}
				header="Customize your service"
				content="Choose between various types of services and customize your order to fit your personal needs. We provide both high quality boosting and coaching services at a reasonable price. Orders can be extended by adding extras such as priority, desired roles and desired champions that the booster should play with. "
			/>
			<Step
				imgUrl={second}
				stepNumber={2}
				header="Select payment option"
				content="Select one of our reliable payment providers to pay for your order. After the payment has been received our boosters will take good care of your LP."
			/>
			<Step
				imgUrl={third}
				stepNumber={3}
				header="Watch your rank skyrocket"
				content="Enjoy a warm cup of coffee as our boosters help you reach your dream rank in no time."
			/>
		</div>
	);
};

export default StepsList;
