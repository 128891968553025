import { LoginResponseBody, TokenRequestBody } from '../types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_REFRESH_TOKEN: RequestMetadata = {
	path: '/Accounts/refresh-token',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authRefreshToken = async (token: string): Promise<RequestResponse<LoginResponseBody>> => {
	return await getRequest<TokenRequestBody, LoginResponseBody>(AUTH_REFRESH_TOKEN, {
		body: {
			token: token,
		},
	});
};

export default authRefreshToken;
