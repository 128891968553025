import React, { useEffect, useState } from 'react';
import Footer from 'components/shared/Footer';
import { BaseOrder, CoachingOrder, GameCredentials, LeagueOrder, NetWinsOrder, PlacementOrder } from 'types/order';
import {
	CoachingCartOrderInfo,
	DuoCartOrderInfo,
	LeagueCartOrderInfo,
	NetwinsCartOrderInfo,
	PlacementCartOrderInfo,
} from './UI';
import { navigate } from '@reach/router';
import {
	createCoachingOrder,
	createLeagueOrder,
	createNetwinsOrder,
	createPlacementOrder,
} from '../../api/requests/requestHandler';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { useDispatch } from 'react-redux';
import { ThunkGlobalDispatch } from '../../redux/root';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';
import { ORDER_TYPES } from '../../constants/general';
const shop = require('src/images/cart/shop.webp');

interface ICartProps extends RouteComponentProps {}

const Cart: React.FC<ICartProps> = () => {
	const [orderDetails, setOrderDetails] = useState<BaseOrder | CoachingOrder>();
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const [gameCredentials, setGameCredentials] = useState<GameCredentials>({
		username: '',
		password: '',
	});
	const [showPassword, setShowPassword] = useState<boolean>(false);

	useEffect(() => {
		const cart = sessionStorage.getItem('cart');
		if (cart) {
			setOrderDetails(JSON.parse(cart) as BaseOrder);
		}
	}, []);

	const renderOrderType = () => {
		switch (orderDetails?.type.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
				return 'League Boost';
			case ORDER_TYPES.DUO:
				return 'Duo Boost';
			case ORDER_TYPES.NETWINS:
				return 'Netwins Boost';
			case ORDER_TYPES.PLACEMENT:
				return 'Placement Boost';
			case ORDER_TYPES.COACHING:
				return 'Coaching';
			default:
				return 'Unhandled :(';
		}
	};

	const renderCartInfoBasedOnOrderType = () => {
		switch (orderDetails?.type.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
				return <LeagueCartOrderInfo order={orderDetails as LeagueOrder} />;
			case ORDER_TYPES.DUO:
				return <DuoCartOrderInfo order={orderDetails as LeagueOrder} />;
			case ORDER_TYPES.NETWINS:
				return <NetwinsCartOrderInfo order={orderDetails as NetWinsOrder} />;
			case ORDER_TYPES.PLACEMENT:
				return <PlacementCartOrderInfo order={orderDetails as PlacementOrder} />;
			case ORDER_TYPES.COACHING:
				return <CoachingCartOrderInfo order={orderDetails as CoachingOrder} />;
			default:
				return 'Unhandled :(';
		}
	};

	const handleOrderCreation = () => {
		const newOrder = { ...(orderDetails as BaseOrder | CoachingOrder) };
		newOrder.gameCredentials = gameCredentials;

		switch (orderDetails?.type.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
			case ORDER_TYPES.DUO:
				createLeagueOrder(newOrder as LeagueOrder).then((res) => {
					if (res.headers) {
						const location = res.headers.get('Location');
						if (location !== null) {
							thunkDispatch(loadLoadingScreen(false));
							navigate(`/payment/${location}`);
						}
					}
				});
				sessionStorage.removeItem('cart');
				break;
			case ORDER_TYPES.NETWINS:
				createNetwinsOrder(newOrder as NetWinsOrder).then((res) => {
					if (res.headers) {
						const location = res.headers.get('Location');
						if (location !== null) {
							thunkDispatch(loadLoadingScreen(false));
							navigate(`/payment/${location}`);
						}
					}
				});
				sessionStorage.removeItem('cart');
				break;
			case ORDER_TYPES.PLACEMENT:
				createPlacementOrder(newOrder as PlacementOrder).then((res) => {
					if (res.headers) {
						const location = res.headers.get('Location');
						if (location !== null) {
							thunkDispatch(loadLoadingScreen(false));
							navigate(`/payment/${location}`);
						}
					}
				});
				sessionStorage.removeItem('cart');
				break;
			case ORDER_TYPES.COACHING:
				createCoachingOrder(newOrder as CoachingOrder).then((res) => {
					if (res.headers) {
						const location = res.headers.get('Location');
						if (location !== null) {
							thunkDispatch(loadLoadingScreen(false));
							navigate(`/payment/${location}`);
						}
					}
					sessionStorage.removeItem('cart');
				});
				break;
			default:
		}
	};

	const renderBasePrice = () => (
		<>
			<h2 className="cart-navigation__price cart-navigation__price--base">
				Base Price:
				<span>{orderDetails?.basePrice} €</span>
			</h2>
			<h2 className="cart-navigation__price cart-navigation__price--offer">
				Special offer:
				<span>-{Math.ceil(100 - (orderDetails?.totalPrice! / orderDetails?.basePrice!) * 100).toFixed(0)}%</span>
			</h2>
		</>
	);

	const renderCredentialsInputs = () => (
		<div className="game-credentials">
			<h4>
				Game credentials<span>*</span>:
			</h4>
			<label>
				Username
				<input
					required
					value={gameCredentials.username}
					placeholder="Username"
					onChange={(e) => setGameCredentials({ ...gameCredentials, username: e.target.value } as GameCredentials)}
					type="text"
				/>
			</label>
			<div className="pass-wrapper">
				<label>
					Password
					<input
						required
						value={gameCredentials.password}
						onChange={(e) => setGameCredentials({ ...gameCredentials, password: e.target.value } as GameCredentials)}
						type={showPassword ? 'text' : 'password'}
						placeholder="Password"
					/>
				</label>
				<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
					{showPassword ? 'visibility_off' : 'visibility'}
				</span>
			</div>
		</div>
	);

	let areCredentialsMandatory = false;

	if (orderDetails?.type.toLocaleUpperCase() !== ORDER_TYPES.COACHING) {
		let order = orderDetails as BaseOrder;
		if (order && !order.isDuo) {
			areCredentialsMandatory = true;
		}
	}

	const isInvalid = areCredentialsMandatory
		? !!(orderDetails && gameCredentials.password && gameCredentials.username)
		: !!orderDetails;

	const renderCartContent = () => (
		<>
			<h2 className="cart-info__header">Your order:</h2>
			<div className="cart-info__content">
				<h3>
					Order Type: <span>{renderOrderType()}</span>
				</h3>
				<h4>Details:</h4>
				<hr />
				<div className="content-wrapper">
					{renderCartInfoBasedOnOrderType()}
					{areCredentialsMandatory && renderCredentialsInputs()}
				</div>
			</div>
		</>
	);

	return (
		<LayoutRoot isMenuTransparent>
			<div className="cart-wrapper">
				<div className="cart-info">
					{orderDetails?.totalPrice ? (
						renderCartContent()
					) : (
						<h2 className="cart-info__missing">No product has been selected</h2>
					)}
				</div>
				<div className="cart-navigation">
					<img className="cart-navigation__image" alt="shop" src={shop} />

					{orderDetails?.basePrice && renderBasePrice()}

					{orderDetails?.totalPrice && (
						<>
							<h2 className="cart-navigation__price">
								Total:
								<span>{orderDetails?.totalPrice} €</span>
							</h2>
							<button
								onClick={() => {
									handleOrderCreation();
								}}
								disabled={!isInvalid}
							>
								Pay now
							</button>
						</>
					)}
				</div>
			</div>
			<Footer />
			<p className="disclaimer">
				*<em> Are my credentials safe?</em>
				<br /> Yes, all credentials are hashed and stored safely during the order execution. After order has been
				completed they are permamently erased from our database.
			</p>
		</LayoutRoot>
	);
};

export default Cart;
