import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import { AppState } from '../../../redux/root';
import { OrderRank } from '../types/general';
import { convertToRoman } from '../../../utils/stringFormatting';
import clsx from 'clsx';
interface IServicesDropdownProps {
	options?: OrderRank[];
	type: string;
	handleDivisionChange: (rank: OrderRank) => void;
}

const ServicesDropdown: React.FC<IServicesDropdownProps> = ({ options, type, handleDivisionChange }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const { rankFrom, rankTo } = useSelector((state: AppState) => state.payment);

	const wrapperRef = React.createRef<HTMLDivElement>();
	useOutsideAlerter(wrapperRef as React.MutableRefObject<HTMLDivElement>, () => setIsActive(false));

	const renderOptions = () => {
		if (options) {
			let allOptions: JSX.Element[] = [];
			options.map((option, index) => {
				allOptions.push(
					<li
						key={option.name + index}
						onClick={() => {
							handleDivisionChange({ name: option.name, division: option.division });
							setIsActive(!isActive);
						}}
					>
						{'Division '} {convertToRoman(option.division)}
					</li>,
				);
			});
			return allOptions;
		}
	};

	return (
		<div ref={wrapperRef} className={clsx(`services-dropdown--${type}`, 'services-dropdown')}>
			<div onClick={() => setIsActive(!isActive)} className="services-dropdown__selected-option">
				<p>
					Division{` `}
					{convertToRoman(type === 'starting' ? rankFrom?.division : rankTo?.division)}
				</p>
				<span className="material-icons dropdown-icon">arrow_drop_down</span>
			</div>
			<ul className={clsx({ active: isActive }, 'services-dropdown__options')}>{renderOptions()}</ul>
		</div>
	);
};

export default ServicesDropdown;
