import React from 'react';
import Link from 'gatsby-link';
import clsx from 'clsx';
interface ButtonProps {
	variant?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	trigger?: () => void;
	url?: string;
	isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ variant, children, trigger, url, type, isDisabled }) => {
	let button = (
		<button
			disabled={isDisabled}
			onClick={trigger}
			type={type}
			className={clsx({ disabled: isDisabled }, 'btn', `btn-${variant}`)}
		>
			{children}
		</button>
	);
	return url ? <Link to={url}>{button}</Link> : button;
};

export default Button;
