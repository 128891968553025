import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkGlobalDispatch, AppState } from '../../../redux/root';
import {
	dispatchWithOrderPriceCalculation,
	setExtras,
	setOrderType,
	setPlacementPricing,
	setQueueType,
	setRankFrom,
	setServer,
} from '../../../redux/actions/paymentActions';
import {
	TiersPricing,
	OrderRank,
	RequestResponseMultiplier,
	BaseNameMultiplier,
	PayPerGamePricing,
} from '../types/general';
import League from './League';
import ServicesDropdown from '../UI/ServicesDivisionDropdown';
import {
	getExtrasPricing,
	getPlacementPricing,
	getQueueTypePricing,
	getServerPricing,
	getSoloLeaguePricing,
} from '../../../api/requests/requestHandler';
import ServicesMultiplierDropdown from '../UI/ServicesMultiplierDropdown';
import ExtrasSection from '../UI/ExtrasSection';
import { updateNumberOfGames, updateServiceType } from '../../../redux/actions/generalActions';
import NumericalInput from '../UI/NumericalInput';
import CouponBanner from '../../coupon/CouponBanner';
import ParamsExtras from '../OrderParams/ParamsExtras';

const PlacementBoosting: React.FC = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { rankFrom, queueType, server, placementPricing, orderType, totalPrice } = useSelector(
		(state: AppState) => state.payment,
	);
	const { numberOfGames, serviceType } = useSelector((state: AppState) => state.general);
	const [tiersPricing, setTiersPricing] = useState<TiersPricing>();
	const [queueTypePricing, setQueueTypePricing] = useState<RequestResponseMultiplier>();
	const [serverPricing, setServerPricing] = useState<RequestResponseMultiplier>();
	const MAXIMUM_NUMBER_OF_PLACEMENTS = 11;
	const FIRST_ELEMENT = 0;
	const serviceTypes: BaseNameMultiplier[] = [
		{ name: 'solo', multiplier: 1.0 },
		{ name: 'duo', multiplier: 1.0 },
	];

	const fetchPricingData = async () => {
		let placementsPricing: PayPerGamePricing;

		await Promise.resolve([
			getPlacementPricing().then((res) => {
				if (res.body) {
					placementsPricing = res.body;
					thunkDispatch(setPlacementPricing(res.body));

					thunkDispatch(
						dispatchWithOrderPriceCalculation(setRankFrom({ name: 'Silver', division: 1 }), placementsPricing),
					);
					getQueueTypePricing().then((res) => {
						setQueueTypePricing(res.body?.queueType);
						thunkDispatch(
							dispatchWithOrderPriceCalculation(setQueueType(res.body?.queueType[FIRST_ELEMENT]), placementsPricing),
						);
					});
					getServerPricing().then((res) => {
						setServerPricing(res.body?.server);
						thunkDispatch(
							dispatchWithOrderPriceCalculation(setServer(res.body?.server[FIRST_ELEMENT]), placementsPricing),
						);
					});
					getExtrasPricing().then((res) => {
						thunkDispatch(dispatchWithOrderPriceCalculation(setExtras(res.body), placementsPricing));
					});
				}
			}),
		]);
	};

	useEffect(() => {
		getSoloLeaguePricing().then((res) => {
			//for avatars
			setTiersPricing(res.body);
		});
		if (orderType !== 'placement' || !totalPrice) {
			thunkDispatch(setOrderType('placement'));
			fetchPricingData();
		}
	}, []);

	const handleLeagueSelection = (rank: OrderRank) => {
		thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rank), placementPricing));
	};

	const renderStartingLeagues = () => {
		if (tiersPricing) {
			const avatarIndex = tiersPricing.tiers
				.find((tier) => tier.name === rankFrom?.name)
				?.divisions.findIndex((div) => div.division === rankFrom?.division);
			return tiersPricing.tiers.map((tier) => {
				if (tier.name === rankFrom?.name && avatarIndex !== undefined) {
					if (tier.name === 'Master') {
						return (
							<League
								handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null })}
								name={tier.name}
								imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
								isActive
								key={tier.name + 'default'}
							/>
						);
					}
					return (
						<League
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 })}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[avatarIndex].avatar}`}
							isActive
							key={tier.name + avatarIndex}
						/>
					);
				}
				if (tier.name === 'Master') {
					return (
						<League
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null })}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
							key={tier.name + 'Master'}
						/>
					);
				}
				return (
					<League
						handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 })}
						name={tier.name}
						imgSrc={`https://data.bamboosting.net/${tier.divisions[tier.divisions.length - 1].avatar}`}
						key={tier.name + 'Last'}
					/>
				);
			});
		}
	};

	const createOptionsForDropdown = (type: string): OrderRank[] => {
		const ranksToRender: OrderRank[] = [];
		if (tiersPricing && type === 'starting') {
			tiersPricing.tiers
				.find((tier) => tier.name === rankFrom?.name)
				?.divisions.forEach((div) => ranksToRender.push({ name: div.name, division: div.division }));
		}
		return ranksToRender;
	};

	const handleNumberOfGamesUpdate = (type: string) => {
		const games = numberOfGames;
		if (type === 'up' && games + 1 < MAXIMUM_NUMBER_OF_PLACEMENTS) {
			thunkDispatch(dispatchWithOrderPriceCalculation(updateNumberOfGames(games + 1), placementPricing));
		} else if (type === 'down' && numberOfGames > 1) {
			thunkDispatch(dispatchWithOrderPriceCalculation(updateNumberOfGames(games - 1), placementPricing));
		}
	};

	const handleNumberGamesChange = (value: number) => {
		if (value < MAXIMUM_NUMBER_OF_PLACEMENTS) {
			thunkDispatch(dispatchWithOrderPriceCalculation(updateNumberOfGames(value), placementPricing));
		}
	};

	const renderDivisionSelectionSection = () => (
		<>
			<div className="params-header">
				<span>01</span>
				<h2>Your previous league:</h2>
			</div>
			<div className="leagues-list">{renderStartingLeagues()}</div>
			{rankFrom?.name !== 'Master' && (
				<ServicesDropdown
					handleDivisionChange={(rank: OrderRank) => handleLeagueSelection(rank)}
					type="starting"
					options={createOptionsForDropdown('starting')}
				/>
			)}
			<div className="win-params">
				<div className="params-header">
					<div className="params-header__headline">
						<span>02</span>
						<h2>Number of games (max. 10):</h2>
					</div>
					<NumericalInput
						handleIncrementAction={() => handleNumberOfGamesUpdate('up')}
						handleDecrementAction={() => handleNumberOfGamesUpdate('down')}
						value={numberOfGames}
						numericalActionToDispatch={(value) => handleNumberGamesChange(value)}
					/>
				</div>
			</div>
		</>
	);

	return (
		<>
			<section className="order-creation">
				<div className="boosting-params container">
					<div className="boosting-params__leagues">{renderDivisionSelectionSection()}</div>
					<div className="boosting-params__dropdowns">
						<div className="dropdowns--queue dropdown--placement">
							<div className="params-header">
								<span>03</span>
								<h2>Queue type</h2>
							</div>
							<ServicesMultiplierDropdown
								handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
									thunkDispatch(dispatchWithOrderPriceCalculation(setQueueType(multiplier), placementPricing));
								}}
								value={queueType?.name}
								options={queueTypePricing}
							/>
						</div>
						<div className="dropdowns--server">
							<div className="params-header">
								<span>04</span>
								<h2>Server</h2>
							</div>
							<ServicesMultiplierDropdown
								handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
									thunkDispatch(dispatchWithOrderPriceCalculation(setServer(multiplier), placementPricing));
								}}
								value={server?.name}
								options={serverPricing}
							/>
						</div>
						<div className="dropdowns--service">
							<div className="params-header">
								<span>05</span>
								<h2>Type of service</h2>
							</div>
							<ServicesMultiplierDropdown
								handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
									thunkDispatch(
										dispatchWithOrderPriceCalculation(updateServiceType(multiplier.name), placementPricing),
									);
								}}
								value={serviceType}
								options={serviceTypes}
							/>
						</div>
					</div>
					<ParamsExtras headlineNumber={'06'} />
				</div>
			</section>
		</>
	);
};

export default PlacementBoosting;
