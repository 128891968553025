import React, { useState } from 'react';
import Link from 'gatsby-link';
import { AppState, ThunkGlobalDispatch } from '../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchLogout } from '../../redux/actions/authActions';
import { USER_ROLES } from '../../constants/general';
const logo = require('../../images/logomin.png');
import clsx from 'clsx';

interface IMenuProps {
	isTransparent?: boolean;
}

const Menu: React.FC<IMenuProps> = ({ isTransparent }) => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const { username, accessToken, role, id } = useSelector((state: AppState) => state.auth);
	const { orderType } = useSelector((state: AppState) => state.payment);
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	let boostingLink = '';

	if (orderType !== 'coaching') {
		boostingLink = `/boosting/${orderType ?? 'league'}`;
	} else {
		boostingLink = `/boosting/league`;
	}

	const handleLogoutClick = () => {
		if (accessToken) {
			thunkDispatch(dispatchLogout());
		}
	};

	const renderAuthLinks = () => (
		<>
			{role?.toLocaleUpperCase() !== USER_ROLES.USER && (
				<li className="auth-item">
					<Link activeClassName="nav-link--active" className="nav-link" to="/orders-panel">
						Orders Panel
					</Link>
				</li>
			)}
			{role?.toLocaleUpperCase() !== USER_ROLES.ADMIN && (
				<li className="auth-item">
					<Link activeClassName="nav-link--active" className="nav-link" to="/my-orders">
						My Orders
					</Link>
				</li>
			)}
			<li className="auth-item">
				<Link activeClassName="nav-link--active" className="nav-link" to={`/user/${id}`}>
					{username}
				</Link>
			</li>
			<li className="auth-item">
				<Link onClick={() => handleLogoutClick()} className="nav-link" to="/">
					Logout
				</Link>
			</li>
		</>
	);

	const renderUnauthLinks = () => (
		<>
			<li className="auth-item">
				<Link activeClassName="nav-link--active" className="nav-link" to="/login">
					Login
				</Link>
			</li>
			<li className="auth-item">
				<Link activeClassName="nav-link--active" className="nav-link" to="/register">
					Register
				</Link>
			</li>
		</>
	);

	return (
		<nav className={clsx({ transparent: isTransparent }, 'nav-menu')}>
			<div className="menu-button">
				<Link className="nav-brand" to="/">
					<img src={logo} alt="logo" />
				</Link>
				<a onClick={() => setIsCollapsed(!isCollapsed)} className="material-icons menu-button__icon">
					{isCollapsed ? 'close' : 'menu_open'}
				</a>
			</div>
			<ul className={clsx({ collapsed: isCollapsed }, 'nav-links')}>
				<li>
					<Link activeClassName="nav-link--active" className="nav-link" to="/">
						Home
					</Link>
				</li>
				{role?.toLocaleUpperCase() !== USER_ROLES.BOOSTER && (
					<>
						<li>
							<Link partiallyActive activeClassName="nav-link--active" className="nav-link" to={boostingLink}>
								Boosting
							</Link>
						</li>
						<li>
							<Link activeClassName="nav-link--active" className="nav-link" to="/coaching">
								Coaching
							</Link>
						</li>
					</>
				)}

				<li>
					<Link activeClassName="nav-link--active" className="nav-link" to="/faq">
						FAQ
					</Link>
				</li>
				<li>
					<Link activeClassName="nav-link--active" className="nav-link" to="/boosters">
						Boosters
					</Link>
				</li>
				<li>
					<Link activeClassName="nav-link--active" className="nav-link" to="/contact">
						Contact
					</Link>
				</li>
				<div className="auth-items">{username ? renderAuthLinks() : renderUnauthLinks()}</div>
			</ul>
		</nav>
	);
};

export default Menu;
