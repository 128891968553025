import React from 'react';

interface ICouponInputProps {
	handleCouponSubmit?: () => void;
	coupon?: string;
	setCoupon: (value: string) => void;
}

const CouponInput: React.FC<ICouponInputProps> = ({ handleCouponSubmit, coupon, setCoupon }) => {
	return (
		<div className="coupon">
			<input value={coupon} onChange={(e) => setCoupon(e.target.value)} type="text" className="coupon__input" />
			<button disabled={!(coupon!.length > 0)} onClick={handleCouponSubmit} type="button" className="coupon__button">
				+ ADD
			</button>
		</div>
	);
};

export default CouponInput;
