import React, { useState, useEffect, useRef } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import clsx from 'clsx';

interface IDropdownProps {
	label: string;
	options: string[];
	handleFilterSelection: (value: string) => void;
}

const BoosterDropdown: React.FC<IDropdownProps> = ({ label, options, handleFilterSelection }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<string>();

	const renderOptions = () => {
		let allOptions: JSX.Element[] = [];
		allOptions.push(
			<li key={'all'} onClick={() => handleOptionSelection('All')}>
				All
			</li>,
		);
		options.forEach((option) => {
			allOptions.push(
				<li key={option} onClick={() => handleOptionSelection(option)}>
					{option}
				</li>,
			);
		});
		return allOptions;
	};

	const handleOptionSelection = (option: string) => {
		setSelectedOption(option);
		handleFilterSelection(option);
		setIsActive(!isActive);
	};

	useEffect(() => {
		setSelectedOption('All');
	}, [setSelectedOption]);

	const wrapperRef = React.createRef<HTMLDivElement>();
	useOutsideAlerter(wrapperRef as React.MutableRefObject<HTMLDivElement>, () => setIsActive(false));

	return (
		<div ref={wrapperRef} className="dropdown">
			<label className="dropdown__label">{label}</label>

			<div onClick={() => setIsActive(!isActive)} className="dropdown__selected-option">
				<p>{selectedOption}</p>
				<span className="material-icons dropdown-icon">arrow_drop_down</span>
			</div>
			<ul className={clsx({ active: isActive }, 'dropdown__options')}>{renderOptions()}</ul>
		</div>
	);
};

export default BoosterDropdown;
