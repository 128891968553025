import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { QueueTypeAPIMultiplier } from '../types/general';

const GET_QUEUE_TYPE_PRICING: RequestMetadata = {
	path: '/data/pricing/multipliers/pricing-queue-type.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetQueueTypePricingResponseBody = QueueTypeAPIMultiplier;

export const getQueueTypePricing = async (): Promise<RequestResponse<GetQueueTypePricingResponseBody>> => {
	return await getRequest<undefined, GetQueueTypePricingResponseBody>({ ...GET_QUEUE_TYPE_PRICING });
};

export default getQueueTypePricing;
