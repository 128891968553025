import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
interface IBoostingTypeProps {
	pathToBoostingType: string;
	boostingTypeName: string;
	isActive?: boolean;
}

const BoostingType: React.FC<IBoostingTypeProps> = ({ pathToBoostingType, boostingTypeName, isActive }) => {
	return (
		<Link to={`/boosting/${pathToBoostingType}`} className={clsx({ active: isActive }, 'boosting-type')}>
			{boostingTypeName}
		</Link>
	);
};

export default BoostingType;
