import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/root';
import Button from 'components/shared/Button';
import { USER_ROLES, ORDER_TYPES, QUEUE_TYPES, ORDER_STATUS } from '../../../constants/general';
import {
	APILeagueOrder,
	APINetWinsOrder,
	APICoachingOrder,
	APIPlacementOrder,
	BoosterProfile,
	GameCredentials,
} from 'types/order';
import { navigate } from 'gatsby';
import clsx from 'clsx';

interface IOrderProps {
	id: string;
	isOdd?: boolean;
	index: number;
	type: string;
	server: string;
	totalPrice: number;
	status: string;
	orderDetails: APILeagueOrder | APINetWinsOrder | APICoachingOrder | APIPlacementOrder;
	createdAt: string;
	customerId: string | null;
	takenAt: string | null;
	takenBy: BoosterProfile | null;
	lastUpdateDate: string | null;
	country: string | undefined;
	countryFlag: string | undefined;
	handleOrderPanelButtonClick: () => void;
	handlePersonalPanelButtonClick: () => void;
	isPersonal?: boolean;
	gameCredentials?: GameCredentials;
}

const Order: React.FC<IOrderProps> = ({
	id,
	isOdd,
	index,
	type,
	totalPrice,
	server,
	status,
	orderDetails,
	createdAt,
	customerId,
	takenAt,
	takenBy,
	lastUpdateDate,
	country,
	countryFlag,
	isPersonal,
	gameCredentials,
	handleOrderPanelButtonClick,
	handlePersonalPanelButtonClick,
}) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const { role } = useSelector((state: AppState) => state.auth);

	const currentRole = role?.toLocaleUpperCase();

	const renderSelectedRoles = (selectedRoles: string[]) => {
		let text: string = '';
		if (selectedRoles && selectedRoles?.length > 1) {
			text += 'Selected roles: \n';
		}
		if (selectedRoles && selectedRoles?.length > 1) {
			selectedRoles.forEach((role, index) => {
				text += role.toLocaleUpperCase();
				if (index !== selectedRoles.length - 1) {
					text += ', ';
				}
			});
		}
		return text;
	};

	const renderPickedHeroes = (pickedChampions: string[]) => {
		let text: string = '';
		if (pickedChampions && pickedChampions?.length > 1) {
			text += 'Picked champions: \n';
		}
		if (pickedChampions && pickedChampions?.length > 1) {
			pickedChampions.forEach((champion, index) => {
				text += champion.toLocaleUpperCase();
				if (index !== pickedChampions.length - 1) {
					text += ', ';
				}
			});
		}
		return text;
	};

	const renderBoosterInfo = () => (
		<>
			{currentRole !== USER_ROLES.BOOSTER && (
				<>
					<h3>BOOSTER INFORMATION:</h3>
					<div className="summary__row">
						<span className="summary__row--attribute">TAKEN BY: </span>
						<span>{takenBy ? takenBy.username : 'NOT YET ASSIGNED'}</span>
						{takenBy && (
							<>
								<span className="summary__row--attribute">BOOSTER'S DISCORD: </span>
								<span>{takenBy ? takenBy.discord : 'NOT YET ASSIGNED'}</span>
							</>
						)}
						{lastUpdateDate && status === ORDER_STATUS.DONE && (
							<>
								<span className="summary__row--attribute">FINISHED AT: </span>
								<span>
									<span>
										{lastUpdateDate && new Date(lastUpdateDate).toLocaleDateString()}
										{` `}
										{lastUpdateDate && new Date(lastUpdateDate).toLocaleTimeString()}
									</span>
								</span>
							</>
						)}
					</div>
				</>
			)}
		</>
	);

	const renderAdditionalInfo = () => (
		<>
			<h3>ADDITIONAL INFORMATION:</h3>
			<div className="summary__row">
				<span className="summary__row--attribute">VPN COUNTRY: </span>
				<span className="summary__row--country">
					{country}
					<img src={countryFlag} />
				</span>
				<span className="summary__row--attribute">CREATED AT: </span>
				<span>
					{new Date(createdAt).toLocaleDateString()}
					{` `}
					{new Date(createdAt).toLocaleTimeString()}
				</span>
				{takenAt && (
					<>
						<span className="summary__row--attribute">TAKEN AT: </span>
						<span>
							{takenAt && new Date(takenAt).toLocaleDateString()}
							{` `}
							{takenAt && new Date(takenAt).toLocaleTimeString()}
						</span>
					</>
				)}
			</div>
			{currentRole === USER_ROLES.ADMIN && (
				<div className="summary__row summary__row--admin">
					<span className="summary__row--attribute">ORDER ID: </span>
					<span>{id}</span>
					<span className="summary__row--attribute">CREATED BY: </span>
					<span>{customerId}</span>
					{lastUpdateDate && (
						<>
							<span className="summary__row--attribute">LAST UDPATE: </span>
							<span>
								{lastUpdateDate && new Date(lastUpdateDate).toLocaleDateString()}
								{` `}
								{lastUpdateDate && new Date(lastUpdateDate).toLocaleTimeString()}
							</span>
						</>
					)}
				</div>
			)}
		</>
	);

	const renderSummary = () => {
		switch (type.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
			case ORDER_TYPES.DUO: {
				const details = orderDetails as APILeagueOrder;

				return (
					<>
						<div className="summary__row">
							<span className="summary__row--attribute">DIVISION BOOST: </span>
							<span>
								FROM {details.rankFrom.name.toLocaleUpperCase() + ' ' + details.rankFrom.division} TO{' '}
								{details.rankTo.name.toLocaleUpperCase() + ' ' + details.rankTo.division}
							</span>
							<span className="summary__row--attribute">QUEUE:</span>
							<span>
								{details.multipliers.queueType.name.toLocaleUpperCase() === QUEUE_TYPES.SOLO
									? 'SOLO/DUO (5v5)'
									: 'FLEX (5v5)'}
							</span>
							<span className="summary__row--attribute">EXTRAS:</span>
							<span>{details.roles.length < 1 && details.champions.length < 1 && 'NONE'}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">DUO TYPE: </span>
							<span>{details.isDuo ? 'DUO' : 'SOLO'}</span>
							<span className="summary__row--attribute">LP GAIN:</span>
							<span>{details.multipliers.lpGain.range.toLocaleUpperCase()}</span>
							<span>{renderSelectedRoles(details.roles)}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">PRIORITY: </span>
							<span className={`${details.isPriority && 'priority'}`}>
								{details.isPriority ? 'PRIORITY ORDER' : 'NORMAL'}
							</span>
							<span className="summary__row--attribute">STARTING LP:</span>
							<span>{details.multipliers.startingLP.range.toLocaleUpperCase()}</span>
							<span>{renderPickedHeroes(details.champions)}</span>
						</div>
						{renderBoosterInfo()}
						{renderAdditionalInfo()}
					</>
				);
			}
			case ORDER_TYPES.NETWINS: {
				const details = orderDetails as APINetWinsOrder;

				return (
					<>
						<div className="summary__row">
							<span className="summary__row--attribute">NET-WINS BOOST: </span>
							<span>{`${details.rank.name.toLocaleUpperCase()}${details.rank.division}`}</span>
							<span className="summary__row--attribute">QUEUE:</span>
							<span>
								{details.multipliers.queueType.name.toLocaleUpperCase() === QUEUE_TYPES.SOLO
									? 'SOLO/DUO (5v5)'
									: 'FLEX (5v5)'}
							</span>
							<span className="summary__row--attribute">EXTRAS:</span>
							<span>{details.roles.length < 1 && details.champions.length < 1 && 'NONE'}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">DUO TYPE: </span>
							<span>{details.isDuo ? 'DUO' : 'SOLO'}</span>
							<span className="summary__row--attribute">NR OF GAMES:</span>
							<span>{details.netWinsQuantity}</span>
							<span>{renderSelectedRoles(details.roles)}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">PRIORITY: </span>
							<span className={`${details.isPriority && 'priority'}`}>
								{details.isPriority ? 'PRIORITY ORDER' : 'NORMAL'}
							</span>
							<span className="summary__row--attribute"></span>
							<span></span>
							<span>{renderPickedHeroes(details.champions)}</span>
						</div>
						{renderBoosterInfo()}
						{renderAdditionalInfo()}
					</>
				);
			}
			case ORDER_TYPES.PLACEMENT: {
				const details = orderDetails as APIPlacementOrder;

				return (
					<>
						<div className="summary__row">
							<span className="summary__row--attribute">PREVIOUS RANK: </span>
							<span>{`${details.previousRank.name.toLocaleUpperCase()}${details.previousRank.division}`}</span>
							<span className="summary__row--attribute">QUEUE:</span>
							<span>
								{details.multipliers.queueType.name.toLocaleUpperCase() === QUEUE_TYPES.SOLO
									? 'SOLO/DUO (5v5)'
									: 'FLEX (5v5)'}
							</span>
							<span className="summary__row--attribute">EXTRAS:</span>
							<span>{details.roles.length < 1 && details.champions.length < 1 && 'NONE'}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">DUO TYPE: </span>
							<span>{details.isDuo ? 'DUO' : 'SOLO'}</span>
							<span className="summary__row--attribute">NR OF GAMES:</span>
							<span>{details.placementWinsQuantity}</span>
							<span>{renderSelectedRoles(details.roles)}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">PRIORITY: </span>
							<span className={`${details.isPriority && 'priority'}`}>
								{details.isPriority ? 'PRIORITY ORDER' : 'NORMAL'}
							</span>
							<span className="summary__row--attribute"></span>
							<span></span>
							<span>{renderPickedHeroes(details.champions)}</span>
						</div>
						{renderBoosterInfo()}
						{renderAdditionalInfo()}
					</>
				);
			}
			case ORDER_TYPES.COACHING: {
				const details = orderDetails as APICoachingOrder;

				return (
					<>
						<div className="summary__row">
							<span className="summary__row--attribute">COACHING: </span>
							<span>{details.numberOfHours} HOURS</span>
							<span className="summary__row--attribute">EXTRAS:</span>
							<span>{details.roles.length < 1 && 'NONE'}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">RANK OF COACH: </span>
							<span>{details.rankOfCoach.name.toLocaleUpperCase()}</span>
							<span>{renderSelectedRoles(details.roles)}</span>
						</div>
						<div className="summary__row">
							<span className="summary__row--attribute">ORDER TYPE:</span>
							<span>{details.type.name.toLocaleUpperCase()}</span>
						</div>
						{renderBoosterInfo()}
						{renderAdditionalInfo()}
					</>
				);
			}
		}
	};

	const renderActionButtons = () => {
		if (isPersonal && status === ORDER_STATUS.CREATED && currentRole === USER_ROLES.USER) {
			return (
				<Button trigger={() => navigate(`/payment/${id}`)} variant="yellow">
					PAY
				</Button>
			);
		} else if (isPersonal && status !== ORDER_STATUS.DONE && currentRole === USER_ROLES.BOOSTER) {
			return (
				<Button trigger={() => handlePersonalPanelButtonClick()} variant="magenta">
					FINISH
				</Button>
			);
		} else if (!isPersonal && currentRole === USER_ROLES.BOOSTER) {
			return (
				<Button trigger={() => handleOrderPanelButtonClick()} variant="green">
					TAKE
				</Button>
			);
		} else {
			return 'NO ACTIONS AVAILABLE';
		}
	};

	const renderGameCredentials = () => (
		<>
			<h3>GAME CREDENTIALS:</h3>
			<div className="summary__row">
				<span className="summary__row--attribute">USERNAME: </span>
				<span>{gameCredentials?.username}</span>
				<span className="summary__row--attribute">PASSWORD:</span>
				<span>{gameCredentials?.password}</span>
			</div>
		</>
	);

	const renderOrderForRoles = () => (
		<>
			<div className="order__essentials">
				<span className="order__essentials--id">{index}</span>
				<span>{type}</span>
				<span>{server}</span>
				<span>{role?.toLocaleUpperCase() === USER_ROLES.BOOSTER ? (totalPrice * 0.4).toFixed(2) : totalPrice} €</span>
				<span className="order__essentials--status">{status}</span>
				<span onClick={() => setIsActive(!isActive)} className="material-icons">
					keyboard_arrow_up
				</span>
				<span>{renderActionButtons()}</span>
			</div>
			<div className="order__summary">
				{role?.toLocaleUpperCase() === USER_ROLES.BOOSTER && status === ORDER_STATUS.TAKEN
					? renderGameCredentials()
					: null}
				<h3>{type.toLocaleUpperCase() === ORDER_TYPES.COACHING ? 'COACHING' : 'BOOSTING'} SUMMARY:</h3>
				{renderSummary()}
			</div>
		</>
	);
	return <div className={clsx({ odd: isOdd, active: isActive }, 'order')}>{renderOrderForRoles()}</div>;
};

export default Order;
