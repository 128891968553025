import { Link } from '@reach/router';
import React from 'react';

interface IFooterProps {
	isFull?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ isFull }) => {
	return (
		<footer>
			{isFull && (
				<p>
					Bamboosting.net isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone
					officially involved in producing or managing League of Legends. League of Legends and Riot Games are
					trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
				</p>
			)}
			<span>
				© {new Date().getFullYear().toString()} Bamboosting.net All Rights Reserved <br />
				<Link to="/terms-of-use">Terms of use</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
			</span>
		</footer>
	);
};

export default Footer;
