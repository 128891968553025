import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import AnimatedComponent from 'components/animation/AnimatedComponent';

interface IAppearOnFocusComponentProps {
	className?: string;
}

const AppearOnFocusComponent: React.FC<IAppearOnFocusComponentProps> = ({ children, className }) => {
	const [entered, setEntered] = useState<boolean>(false);

	return (
		<AnimatedComponent className={className} in={entered}>
			<Waypoint
				onEnter={() => {
					setTimeout(() => {
						setEntered(true);
					}, 300);
				}}
			>
				{children}
			</Waypoint>
		</AnimatedComponent>
	);
};

export default AppearOnFocusComponent;
