import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoachingPricing, getServerPricing } from '../../../api/requests/requestHandler';
import { updateCoachingType, updateNumberOfHours, updateRankOfCoach } from '../../../redux/actions/generalActions';
import {
	dispatchWithCoachingPriceCalculation,
	setCoachingParams,
	setOrderType,
	setServer,
} from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { BaseNameMultiplier, CoachingPricing, RequestResponseMultiplier } from 'types/general';
import NumericalInput from '../../boosting/UI/NumericalInput';
import ServicesMultiplierDropdown from '../../boosting/UI/ServicesMultiplierDropdown';
import Button from 'components/shared/Button';
import CoachingLines from './CoachingLines';
import CoachRanks from './CoachRanks';

const FIRST_ELEMENT = 0;

const CoachingOrderCreation: React.FC = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { server, coachingParams } = useSelector((state: AppState) => state.payment);
	const { numberOfHours, coachingType } = useSelector((state: AppState) => state.general);
	const [serverPricing, setServerPricing] = useState<RequestResponseMultiplier>();
	const [coachingPricing, setCoachRankPricing] = useState<RequestResponseMultiplier>();

	const fetchPricingData = async () => {
		thunkDispatch(setOrderType('coaching'));
		let coachingPricing: CoachingPricing | undefined;
		await Promise.resolve(
			getCoachingPricing().then((res) => {
				coachingPricing = res.body?.pricing;
				thunkDispatch(setCoachingParams(res.body?.pricing));
				setCoachRankPricing(res.body?.pricing.rankOfCoach);
				getServerPricing().then((res) => {
					setServerPricing(res.body?.server);
					thunkDispatch(
						dispatchWithCoachingPriceCalculation(setServer(res.body?.server[FIRST_ELEMENT]), coachingPricing),
					);
				});
				if (res.body?.pricing.rankOfCoach !== undefined) {
					thunkDispatch(
						dispatchWithCoachingPriceCalculation(
							updateRankOfCoach(res.body?.pricing.rankOfCoach[FIRST_ELEMENT].name),
							coachingPricing,
						),
					);
				}
			}),
		);
	};

	useEffect(() => {
		fetchPricingData();
	}, []);

	const handleNumberOfHoursPointsUpdate = (type: string) => {
		const hours = numberOfHours;
		if (type === 'up' && numberOfHours + 1 <= 10) {
			thunkDispatch(dispatchWithCoachingPriceCalculation(updateNumberOfHours(hours + 1), coachingParams));
		} else if (type === 'down' && numberOfHours > 1) {
			thunkDispatch(dispatchWithCoachingPriceCalculation(updateNumberOfHours(hours - 1), coachingParams));
		}
	};

	const handleInputChange = (value: number) => {
		if (value > 0 && value <= 10) {
			thunkDispatch(dispatchWithCoachingPriceCalculation(updateNumberOfHours(value), coachingParams));
		}
	};

	const handleButtonClick = (coachingType: string) => {
		thunkDispatch(dispatchWithCoachingPriceCalculation(updateCoachingType(coachingType), coachingParams));
	};

	return (
		<section className="order-creation coaching-order">
			<div className="coaching-params container">
				<div className="coaching-params__left">
					<div className="coaching-params__type">
						<div className="params-header">
							<span>01</span>
							<h2>Type of coaching</h2>
						</div>
						<div className="params-button">
							<Button
								trigger={() => handleButtonClick('regular')}
								variant={coachingType === 'regular' ? 'active' : 'purchase'}
							>
								Regular
							</Button>
							<Button
								trigger={() => handleButtonClick('premium')}
								variant={coachingType === 'premium' ? 'active' : 'purchase'}
							>
								Premium
							</Button>
						</div>
					</div>
					<div className="coaching-params__lines">
						<div className="params-header">
							<span>02</span>
							<h2>Specific lanes</h2>
						</div>
						{coachingParams && <CoachingLines coachingPricing={coachingParams} />}
					</div>
				</div>
				<div className="coaching-params__right">
					<div className="coaching-params__coach">
						<div className="params-header">
							<span>03</span>
							<h2>Rank of coach</h2>
						</div>
						{coachingParams && <CoachRanks coachingPricing={coachingParams} />}
					</div>
					<div className="coaching-params__dropdown">
						<div className="dropdowns--server">
							<div className="params-header">
								<span>04</span>
								<h2>Your server</h2>
							</div>
							<ServicesMultiplierDropdown
								handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
									thunkDispatch(dispatchWithCoachingPriceCalculation(setServer(multiplier), coachingParams));
								}}
								value={server?.name}
								options={serverPricing}
							/>
						</div>
					</div>
					<div className="coaching-params__hours">
						<div className="params-header">
							<span>05</span>
							<h2>Number of hours</h2>
						</div>
						<NumericalInput
							variant="orange"
							handleIncrementAction={() => handleNumberOfHoursPointsUpdate('up')}
							handleDecrementAction={() => handleNumberOfHoursPointsUpdate('down')}
							value={numberOfHours}
							handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(Number(e.target.value))}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CoachingOrderCreation;
