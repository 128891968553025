import React from 'react';
import { useDispatch } from 'react-redux';
import { dispatchWithOrderPriceCalculation, setQueueType } from '../../../redux/actions/paymentActions';
import { ThunkGlobalDispatch } from '../../../redux/root';
import { BaseNameMultiplier, OrderParamsProps } from '../types/general';
import ServicesMultiplierDropdown from '../UI/ServicesMultiplierDropdown';

interface ParamsQueueTypeProps extends OrderParamsProps {}

const ParamsQueueType: React.FC<ParamsQueueTypeProps> = ({ value, tiersPricing, paramPricing }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	return (
		<div className="dropdowns--queue">
			<div className="params-header">
				<span>05</span>
				<h2>Queue type</h2>
			</div>
			<ServicesMultiplierDropdown
				handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
					thunkDispatch(dispatchWithOrderPriceCalculation(setQueueType(multiplier), tiersPricing));
				}}
				value={value}
				options={paramPricing}
			/>
		</div>
	);
};

export default ParamsQueueType;
