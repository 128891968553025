import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { TiersPricing } from '../types/general';

const GET_DUO_LEAGUE_PRICING: RequestMetadata = {
	path: '/data/pricing/boosting/league/duo/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetDuoLeaguePricingResponseBody = TiersPricing;

export const getDuoLeaguePricing = async (): Promise<RequestResponse<GetDuoLeaguePricingResponseBody>> => {
	return await getRequest<undefined, GetDuoLeaguePricingResponseBody>({ ...GET_DUO_LEAGUE_PRICING });
};

export default getDuoLeaguePricing;
