import React from 'react';
import { APIOrder } from 'types/order';
import Order from './Order';
import { CountryData } from 'types/general';
import { useState } from 'react';
import { useEffect } from 'react';
import { USER_ROLES } from '../../../constants/general';
import Button from 'components/shared/Button';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/root';
import { Link } from 'gatsby';
const chall = require('../../../images/ranks/challenger.png');

interface IOrdersListProps {
	data: APIOrder[] | undefined;
	handleOrderPanelButtonClick: (id: string) => void;
	handlePersonalPanelButtonClick: (id: string) => void;
	isPersonal?: boolean;
}

const OrdersList: React.FC<IOrdersListProps> = ({
	data,
	handleOrderPanelButtonClick,
	isPersonal,
	handlePersonalPanelButtonClick,
}) => {
	const [countries, setCountries] = useState<CountryData[]>();
	const { role } = useSelector((state: AppState) => state.auth);

	useEffect(() => {
		fetch('https://restcountries.com/v2/all')
			.then((res) => res.json())
			.then((data) => {
				setCountries(data);
			});
	}, []);

	const renderOrders = () => {
		if (data === undefined || (data?.length < 1 && role?.toLocaleUpperCase() !== USER_ROLES.USER)) {
			return <p>No orders found</p>;
		} else if (data === undefined || (data?.length < 1 && role?.toLocaleUpperCase() === USER_ROLES.USER)) {
			return (
				<div className="encouragement">
					<img src={chall} />
					<p>Create a new order and boost your rank.</p>
					<Link to="/boosting/league">
						<Button type="button" variant="green">
							+ Create order
						</Button>
					</Link>
				</div>
			);
		}

		return data?.map((order, index) => {
			return (
				<Order
					isPersonal={isPersonal}
					handleOrderPanelButtonClick={() => handleOrderPanelButtonClick(order.id)}
					handlePersonalPanelButtonClick={() => handlePersonalPanelButtonClick(order.id)}
					id={order.id}
					server={order.server}
					totalPrice={order.totalPrice}
					type={order.type.toLocaleUpperCase()}
					index={index + 1}
					key={order.id}
					isOdd={index % 2 === 0}
					status={order.status.toLocaleUpperCase()}
					orderDetails={order.orderType}
					createdAt={order.createdAt}
					customerId={order.customer.customerId}
					takenAt={order.takenAt}
					takenBy={order.booster}
					lastUpdateDate={order.updatedAt}
					gameCredentials={order.gameCredentials}
					country={
						countries?.find((c) => c.alpha2Code.toLocaleUpperCase() === order.customer.country.toLocaleUpperCase())
							?.name
					}
					countryFlag={
						countries?.find((c) => c.alpha2Code.toLocaleUpperCase() === order.customer.country.toLocaleUpperCase())
							?.flag
					}
				/>
			);
		});
	};

	return <div className="orders-list container">{renderOrders()}</div>;
};

export default OrdersList;
