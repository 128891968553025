import React from 'react';
const visa = require('../../../images/order-summary/visa.png');
const paypal = require('../../../images/order-summary/paypal.png');
const mastercard = require('../../../images/order-summary/mastercard.png');

const Providers = () => (
	<div className="payment-providers">
		<h2>Payment providers:</h2>
		<div className="payment-providers__img">
			<img src={paypal} />
			<img src={visa} />
			<img src={mastercard} />
		</div>
	</div>
);

export default Providers;
