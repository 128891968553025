import React, { useEffect, useState } from 'react';
import AnimatedComponent from '../../animation/AnimatedComponent';
import clsx from 'clsx';
interface ILineProps {
	lineName: string;
	championImage: string;
	isActive?: boolean;
	isOdd?: boolean;
	handleLineSelection: () => void;
}

const Line: React.FC<ILineProps> = ({ lineName, championImage, isActive, isOdd, handleLineSelection }) => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div onClick={() => handleLineSelection()} className={clsx({ active: isActive }, { odd: isOdd }, 'line')}>
			<AnimatedComponent in={isLoaded}>
				<h3 className="line__name">{lineName}</h3>
				<img src={championImage} />
			</AnimatedComponent>
		</div>
	);
};

export default Line;
