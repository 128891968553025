import React from 'react';
import StaticImage from './StaticImage';

interface ICoverPhotoProps {
	filename: string;
	alt: string;
}

const CoverPhoto: React.FC<ICoverPhotoProps> = ({ filename, alt }) => {
	return (
		<div className="cover-wrapper">
			<StaticImage filename={filename} alt={alt} />
		</div>
	);
};

export default CoverPhoto;
