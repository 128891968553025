import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, ThunkGlobalDispatch } from '../../redux/root';
import { dispatchTryAutomaticLogin } from '../../redux/actions/authActions';
import { loadBackdrop, loadLoadingScreen, setCookieVisibility } from '../../redux/actions/generalActions';
import { getCookie } from '../../utils/cookies';

interface IStaticQueryProps {
	site: {
		siteMetadata: {
			title: string;
			description: string;
			keywords: string;
		};
	};
}

const getEnv = (): String => {
	if (typeof window !== 'undefined') {
		if (window.location.href.indexOf('bamboosting.net') > -1) {
			return 'prod';
		}
		return 'dev';
	}
	return '';
};

const SEO: React.FC = () => {
	const { username } = useSelector((state: AppState) => state.auth);
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	useEffect(() => {
		let cookieBar = getCookie('ck_07001aea8517663c');
		if (cookieBar) {
			thunkDispatch(setCookieVisibility(false));
		}
	}, []);

	useEffect(() => {
		if (username === undefined) {
			thunkDispatch(dispatchTryAutomaticLogin());
		}
		setTimeout(() => {
			thunkDispatch(loadLoadingScreen(false));
			thunkDispatch(loadBackdrop(false));
		}, 700);

		return () => {
			thunkDispatch(loadLoadingScreen(true));
			thunkDispatch(loadBackdrop(true));
		};
	}, []);

	return (
		<StaticQuery
			query={graphql`
				query IndexLayoutQuery {
					site {
						siteMetadata {
							title
							description
							keywords
						}
					}
				}
			`}
			render={(data: IStaticQueryProps) => (
				<Helmet
					title={data.site.siteMetadata.title}
					meta={[
						{ name: 'description', content: data.site.siteMetadata.description },
						{ name: 'keywords', content: data.site.siteMetadata.keywords },
					]}
				>
					<script src="https://www.datadoghq-browser-agent.com/datadog-rum.js" type="text/javascript"></script>
					<script>
						{`window.DD_RUM && window.DD_RUM.init({
							clientToken: 'pub2db0f6f93a8b82fc11d25ab5db078735',
							applicationId: '971d21c5-e982-4af0-bfb1-59f165a0405b',
							site: 'datadoghq.com',
							sampleRate: 100,
							env: '${getEnv()}',
					})`}
					</script>
				</Helmet>
			)}
		/>
	);
};

export default SEO;
