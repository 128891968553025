import React from 'react';

const teemo = require('../../images/coupon/teemo.webp');

interface CouponBanner {
	type?: undefined | 'boosting' | 'coaching';
}

const CouponBanner: React.FC<CouponBanner> = ({ type }) => {
	return (
		<div
			style={{ backgroundImage: `url("${teemo}")` }}
			className={`coupon-banner ${type ? 'coupon-banner--' + type : null}`}
		>
			<h3 className="coupon-banner__header">Limited time offer! Use code:</h3>
			<p className="coupon-banner__code">SPRING15</p>
			<p className="coupon-banner__discount">and get 15% off every purchase!</p>
		</div>
	);
};

export default CouponBanner;
