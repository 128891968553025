export const USER_ROLES = {
	ADMIN: 'ADMIN',
	USER: 'USER',
	BOOSTER: 'BOOSTER',
} as const;

export const ORDER_TYPES = {
	LEAGUE: 'LEAGUE',
	DUO: 'DUO',
	PLACEMENT: 'PLACEMENT',
	NETWINS: 'NETWINS',
	COACHING: 'COACHING',
} as const;

export const QUEUE_TYPES = {
	SOLO: 'SOLO',
	FLEX: 'FLEX',
} as const;

export const ORDER_STATUS = {
	CREATED: 'CREATED',
	PAID: 'PAID',
	TAKEN: 'TAKEN',
	DONE: 'DONE',
} as const;

export const COACH_RANK = {
	Master: 'Master',
	Grandmaster: 'Grandmaster',
	Challenger: 'Challenger',
} as const;
