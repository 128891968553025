import React, { useState } from 'react';
import clsx from 'clsx';

export interface IAccordeonProps {
	isOdd?: boolean;
	header: string;
	content: string;
	index: number;
}

const Accordeon: React.FC<IAccordeonProps> = ({ isOdd, header, content, index }) => {
	const [isActive, setIsActive] = useState<boolean>(false);

	return (
		<div
			onClick={() => setIsActive(!isActive)}
			className={clsx({ active: isActive }, 'accordeon', { odd: isOdd }, { even: !isOdd })}
		>
			<div className="accordeon__header">
				<h3>0{index}</h3>
				<p>{header}</p>
				<span onClick={() => setIsActive(!isActive)} className="material-icons">
					keyboard_arrow_up
				</span>
			</div>
			<div className="accordeon__content">
				<p>{content}</p>
			</div>
		</div>
	);
};

export default Accordeon;
