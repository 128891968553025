import React from 'react';
import AppearOnFocusComponent from '../../animation/AppearOnFocusComponent';

interface IStepProps {
	stepNumber: number;
	header: string;
	content: string;
	imgUrl: string;
}

const Step: React.FC<IStepProps> = (props: IStepProps) => {
	const renderEvenStep = () => (
		<AppearOnFocusComponent className="step-wrapper">
			<div className="step">
				<div className="step__image">
					<img src={imgUrl} />
				</div>
				<div className="step__text">
					<h2>
						<span>{`0${stepNumber} `}</span>
						{header}
					</h2>
					<p>{content}</p>
				</div>
			</div>
		</AppearOnFocusComponent>
	);

	const renderOddStep = () => (
		<AppearOnFocusComponent className="step-wrapper">
			<div className="step">
				<div className="step__image step__image--additional">
					<img src={imgUrl} />
				</div>
				<div className="step__text">
					<h2>
						{header}
						<span>{` 0${stepNumber}`}</span>
					</h2>
					<p>{content}</p>
				</div>
				<div className="step__image">
					<img src={imgUrl} />
				</div>
			</div>
		</AppearOnFocusComponent>
	);

	const { stepNumber, header, content, imgUrl } = props;
	return stepNumber % 2 == 0 ? renderOddStep() : renderEvenStep();
};

export default Step;
