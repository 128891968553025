import React from 'react';

interface ILoginInputProps {
	inputText: string;
	isPassword?: boolean;
	isEmail?: boolean;
	isRequired?: boolean;
	handleInputChange: (value: string) => void;
}

const LoginInput: React.FC<ILoginInputProps> = (props: ILoginInputProps) => {
	const { inputText, isPassword, handleInputChange, isRequired, isEmail } = props;

	return (
		<input
			required={isRequired}
			onChange={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e.currentTarget.value)}
			type={isEmail ? 'email' : isPassword ? 'password' : 'text'}
			className="login-input"
			placeholder={inputText}
		/>
	);
};

export default LoginInput;
