import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { StartingLpAPIMultiplier } from '../types/general';

const GET_STARTING_LP_PRICING: RequestMetadata = {
	path: '/data/pricing/multipliers/pricing-starting-lp.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetStartingLpPricingResponseBody = StartingLpAPIMultiplier;

export const getStartingLpPricing = async (): Promise<RequestResponse<GetStartingLpPricingResponseBody>> => {
	return await getRequest<undefined, GetStartingLpPricingResponseBody>({ ...GET_STARTING_LP_PRICING });
};

export default getStartingLpPricing;
