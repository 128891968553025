import {
	PaymentState,
	SET_BASE_PRICE,
	SET_COACHING_PARAMS,
	SET_COUPON,
	SET_EXTRAS,
	SET_IS_PRIORITY,
	SET_LP_GAIN,
	SET_NET_WINS_PRICING,
	SET_ORDER_TYPE,
	SET_PLACEMENT_PRICING,
	SET_QUEUE_TYPE,
	SET_RANK_FROM,
	SET_RANK_TO,
	SET_SERVER_TYPE,
	SET_STARTING_LP,
	SET_TIERS_PRICING,
	SET_TOTAL_PRICE,
} from '../types/paymentTypes';
import { AppActions, ThunkGlobalDispatch, ThunkResult } from '../../redux/root';
import {
	BaseNameMultiplier,
	BaseRangeMultiplier,
	CoachingPricing,
	Division,
	ExtrasMultipliers,
	OrderRank,
	PayPerGamePricing,
	TiersPricing,
} from 'types/general';
import { CombinedState } from 'redux';
import { GeneralState } from '../types/generalTypes';
import { APICoupon } from 'types/order';
import { ORDER_TYPES } from '../../constants/general';

export const setOrderType = (type: string | undefined): AppActions => ({
	type: SET_ORDER_TYPE,
	payload: type,
});

export const setRankFrom = (rank: OrderRank | undefined): AppActions => ({
	type: SET_RANK_FROM,
	payload: rank,
});

export const setRankTo = (rank: OrderRank | undefined): AppActions => ({
	type: SET_RANK_TO,
	payload: rank,
});

export const setStartingLP = (multiplier: BaseRangeMultiplier | undefined): AppActions => ({
	type: SET_STARTING_LP,
	payload: multiplier,
});

export const setLPGain = (multiplier: BaseRangeMultiplier | undefined): AppActions => ({
	type: SET_LP_GAIN,
	payload: multiplier,
});

export const setQueueType = (multiplier: BaseNameMultiplier | undefined): AppActions => ({
	type: SET_QUEUE_TYPE,
	payload: multiplier,
});

export const setServer = (multiplier: BaseNameMultiplier | undefined): AppActions => ({
	type: SET_SERVER_TYPE,
	payload: multiplier,
});

export const setTotalPrice = (totalPrice: number | undefined): AppActions => ({
	type: SET_TOTAL_PRICE,
	payload: totalPrice,
});

export const setIsPriority = (priority: boolean | undefined): AppActions => ({
	type: SET_IS_PRIORITY,
	payload: priority,
});

export const setExtras = (extras: ExtrasMultipliers | undefined): AppActions => ({
	type: SET_EXTRAS,
	payload: extras,
});

export const setCoachingParams = (params: CoachingPricing | undefined): AppActions => ({
	type: SET_COACHING_PARAMS,
	payload: params,
});

export const setTiersPricing = (tiers: TiersPricing | undefined): AppActions => ({
	type: SET_TIERS_PRICING,
	payload: tiers,
});

export const setNetWinsPricing = (pricing: PayPerGamePricing): AppActions => ({
	type: SET_NET_WINS_PRICING,
	payload: pricing,
});

export const setPlacementPricing = (pricing: PayPerGamePricing): AppActions => ({
	type: SET_PLACEMENT_PRICING,
	payload: pricing,
});

export const setCoupon = (coupon: APICoupon): AppActions => ({
	type: SET_COUPON,
	payload: coupon,
})

export const setBasePrice = (basePrice: number | undefined): AppActions => ({
	type: SET_BASE_PRICE,
	payload: basePrice,
})

const handleNetWinsOrderPriceCalculation = (
	netWinsPricing: PayPerGamePricing | undefined,
	getState: () => CombinedState<{
		general: GeneralState;
		payment: PaymentState;
	}>,
) => {
	const { rankFrom, queueType, server } = getState().payment;
	const { serviceType, numberOfGames } = getState().general;
	let priceWithoutMultipliers: number = 0;
	const tierOfGames = netWinsPricing?.pricing.find(
		(t) => t.division === rankFrom?.division && t.name === rankFrom.name,
	);
	if (
		netWinsPricing &&
		tierOfGames !== undefined &&
		queueType !== undefined &&
		server !== undefined
	) {
		priceWithoutMultipliers = tierOfGames?.pricePerWin * numberOfGames;

		let basePrice = priceWithoutMultipliers;

		let totalPrice: number = +(
			priceWithoutMultipliers +
			basePrice * queueType?.multiplier -
			basePrice +
			basePrice * server?.multiplier -
			basePrice
		);

		if (serviceType === 'duo') {
			totalPrice += basePrice * netWinsPricing?.duoMultiplier - basePrice;
		}

		totalPrice = calculateExtrasPricing(totalPrice, getState);

		return +totalPrice.toFixed(2);
	}
};

const handlePlacementOrderPriceCalculation = (
	placementPricing: PayPerGamePricing | undefined,
	getState: () => CombinedState<{
		general: GeneralState;
		payment: PaymentState;
	}>,
) => {
	const { rankFrom, queueType, server } = getState().payment;
	const { serviceType, numberOfGames } = getState().general;
	let priceWithoutMultipliers: number = 0;
	const tierOfGames = placementPricing?.pricing.find(
		(t) => t.division === rankFrom?.division && t.name === rankFrom.name,
	);
	if (placementPricing && tierOfGames !== undefined && queueType !== undefined && server !== undefined) {
		priceWithoutMultipliers = tierOfGames?.pricePerWin * numberOfGames;

		let totalPrice: number =
			priceWithoutMultipliers +
			(priceWithoutMultipliers * queueType?.multiplier - priceWithoutMultipliers) +
			(priceWithoutMultipliers * server?.multiplier - priceWithoutMultipliers);
		if (serviceType === 'duo') {
			totalPrice += priceWithoutMultipliers * placementPricing?.duoMultiplier - priceWithoutMultipliers;
		}

		totalPrice = calculateExtrasPricing(totalPrice, getState);

		return +totalPrice.toFixed(2);
	}
};

const handleLeagueOrderPriceCalculation = (
	tiersPricing: TiersPricing | undefined,
	getState: () => CombinedState<{
		general: GeneralState;
		payment: PaymentState;
	}>,
) => {
	const { rankTo, rankFrom, startingLP, lpGain, queueType, server } = getState().payment;
	const { numberOfMasterPoints } = getState().general;
	let priceWithoutMultipliers: number = 0;
	let tiersArray: Division[] = [];
	if (rankTo && rankFrom && startingLP && lpGain && queueType && server && numberOfMasterPoints) {
		tiersPricing?.tiers.map((tier) => {
			return tier.divisions.map((division) => {
				tiersArray.push(division);
			});
		});
		const indexRankTo = tiersArray.findIndex((div) => div.name === rankTo.name && div.division == rankTo.division);
		const indexRankFrom = tiersArray.findIndex(
			(div) => div.name === rankFrom.name && div.division == rankFrom.division,
		);
		if (rankFrom.name === 'Master') {
			priceWithoutMultipliers =
				tiersArray[indexRankFrom].priceToAdvance *
				(numberOfMasterPoints.desiredPoints - numberOfMasterPoints.startingPoints);
		} else if (rankTo.name === 'Master') {
			for (let i = indexRankFrom; i < indexRankTo; i++) {
				priceWithoutMultipliers += tiersArray[i].priceToAdvance;
			}
			priceWithoutMultipliers += tiersArray[indexRankTo].priceToAdvance * numberOfMasterPoints.desiredPoints;
		} else {
			for (let i = indexRankFrom; i < indexRankTo; i++) {
				priceWithoutMultipliers += tiersArray[i].priceToAdvance;
			}
		}

		let basePrice = priceWithoutMultipliers;

		let totalPrice: number = +(
			priceWithoutMultipliers +
			(basePrice * startingLP?.multiplier - basePrice) +
			(basePrice * lpGain?.multiplier - basePrice) +
			(basePrice * queueType?.multiplier - basePrice) +
			(basePrice * server?.multiplier - basePrice)
		);

		totalPrice = calculateExtrasPricing(totalPrice, getState);

		return +totalPrice.toFixed(2);
	}
};

const calculateExtrasPricing = (
	basePrice: number,
	getState: () => CombinedState<{
		general: GeneralState;
		payment: PaymentState;
	}>,
): number => {
	const { selectedRoles, pickedChampions } = getState().general;
	const { extras, isPriority } = getState().payment;

	let totalPrice = basePrice;

	if (selectedRoles !== undefined && selectedRoles?.length > 1 && extras !== undefined) {
		totalPrice += basePrice * extras?.extras.roles[1].multiplier - basePrice;
	}

	if (pickedChampions !== undefined && pickedChampions?.length > 2 && extras !== undefined) {
		totalPrice += basePrice * extras?.extras.champions[1].multiplier - basePrice;
	}

	if (isPriority && extras !== undefined) {
		totalPrice += basePrice * extras?.extras.priorities[1].multiplier - basePrice;
	}

	return totalPrice;
};

const handleCoachingPriceCalculation = (
	coachingPricing: CoachingPricing | undefined,
	getState: () => CombinedState<{
		general: GeneralState;
		payment: PaymentState;
	}>,
) => {
	const { server } = getState().payment;
	const { numberOfHours, coachRank, coachingType } = getState().general;

	const serverMultiplier = server?.multiplier;
	const coachMultiplier = coachingPricing?.rankOfCoach.find((r) => r.name === coachRank)?.multiplier;
	const coachingTypeMultiplier = coachingPricing?.type.find((t) => t.name === coachingType)?.multiplier;

	if (serverMultiplier && coachingPricing && numberOfHours && coachMultiplier && coachingTypeMultiplier) {
		let basePrice = numberOfHours * coachingPricing?.basePrice;

		const totalPrice =
			basePrice +
			(basePrice * serverMultiplier - basePrice) +
			(basePrice * coachMultiplier - basePrice) +
			(basePrice * coachingTypeMultiplier - basePrice);
		return +totalPrice.toFixed(2);
	}
	return undefined;
};

export const dispatchWithOrderPriceCalculation = (
	action: AppActions,
	tiersPricing?: TiersPricing | PayPerGamePricing | undefined,
): ThunkResult => async (dispatch: ThunkGlobalDispatch, getState) => {
	await Promise.all([dispatch(action)]);
	let totalPrice: number | undefined;
	const { orderType, coupon } = getState().payment;
	
	switch (orderType?.toLocaleUpperCase()) {
		case ORDER_TYPES.LEAGUE:
		case ORDER_TYPES.DUO: {
			const pricing = tiersPricing as TiersPricing;
			if (pricing.tiers !== undefined) {
				totalPrice = handleLeagueOrderPriceCalculation(pricing, getState);
			}
			break;
		}
		case ORDER_TYPES.NETWINS: {
			const pricing = tiersPricing as PayPerGamePricing;
			if (pricing !== undefined) {
				totalPrice = handleNetWinsOrderPriceCalculation(pricing, getState);
			}
			break;
		}
		case ORDER_TYPES.PLACEMENT: {
			const pricing = tiersPricing as PayPerGamePricing;
			if (pricing !== undefined) {
				totalPrice = handlePlacementOrderPriceCalculation(pricing, getState);
			}
			break;
		}
		default: {
			throw new Error("Unsupported order type")
		}
	}
	if(totalPrice && coupon !== undefined) {
		dispatch(setBasePrice(totalPrice));
		totalPrice =  +(totalPrice * (100 - coupon.offPercentage) * 0.01).toFixed(2);
	}
	return dispatch(setTotalPrice(totalPrice));
};

export const dispatchWithCoachingPriceCalculation = (
	action: AppActions,
	coachingPricing: CoachingPricing | undefined,
): ThunkResult => async (dispatch: ThunkGlobalDispatch, getState) => {
	const { coupon } = getState().payment
	await Promise.all([dispatch(action)]);
	let totalPrice = handleCoachingPriceCalculation(coachingPricing, getState);
	if(totalPrice && coupon !== undefined) {
		dispatch(setBasePrice(totalPrice));
		totalPrice = +(totalPrice * ((100 - coupon.offPercentage) * 0.01)).toFixed(2);
	}
	return dispatch(setTotalPrice(totalPrice));
};

export const dispatchUpdateCoupon = (coupon: APICoupon): ThunkResult => async (dispatch: ThunkGlobalDispatch, getState) => {
	const { totalPrice } = getState().payment;
	dispatch(setCoupon(coupon));
	if(totalPrice) {
		dispatch(setBasePrice(totalPrice));
		const newPrice = +(totalPrice * (100 - coupon.offPercentage) * 0.01).toFixed(2);
		dispatch(setTotalPrice(newPrice));
	}
} 
