import React from 'react';
import { LeagueOrder } from 'types/order';

interface IDuoCartOrderInfo {
	order: LeagueOrder;
}

const DuoCartOrderInfo: React.FC<IDuoCartOrderInfo> = ({ order }) => {
	return (
		<div className="cart-order-info cart-order-info--duo">
			<p>
				{order.rankFrom.name} {order.rankFrom.division} &rarr; {order.rankTo.name} {order.rankFrom.division}
			</p>
			<p>
				Duo Order: <span>✔️</span>
			</p>
			<p>
				Priority? <span>{order.isPriority ? '✔️' : '-'}</span>
			</p>
			<p>
				LP Gain: <span>{order.multipliers.lpGain.range}</span>
			</p>
			<p>
				Queue Type: <span>{order.multipliers.queueType.name}</span>
			</p>
			<p>
				Server: <span>{order.multipliers.server.name}</span>
			</p>
			<p>
				Starting LP: <span>{order.multipliers.startingLP.range}</span>
			</p>
			{order.roles.length > 1 && (
				<p>
					Roles:{' '}
					<span>
						{order.roles.map((r, index) => {
							if (index < order.roles.length - 1) {
								return `${r}, `;
							}
							return `${r} `;
						})}
					</span>
				</p>
			)}
			{order.champions.length > 1 && (
				<p>
					Champions:{' '}
					<span>
						{order.champions.map((c, index) => {
							if (index < order.champions.length - 1) {
								return `${c}, `;
							}
							return `${c} `;
						})}
					</span>
				</p>
			)}
		</div>
	);
};

export default DuoCartOrderInfo;
