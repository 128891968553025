import { navigate } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createCoachingOrder, getCouponByCode } from '../../../api/requests/requestHandler';
import { loadLoadingScreen } from '../../../redux/actions/generalActions';
import { dispatchUpdateCoupon } from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { CoachingOrder } from 'types/order';
import CouponInput from '../../boosting/UI/CouponInput';
import FinalPrice from '../../boosting/UI/FinalPrice';
import CouponBanner from '../../coupon/CouponBanner';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
const amumu = require('../../../images/order-summary/amumu.png');
const visa = require('../../../images/order-summary/visa.png');
const paypal = require('../../../images/order-summary/paypal.png');
const mastercard = require('../../../images/order-summary/mastercard.png');

interface ICoachingSummarySectionProps {
	summaryImage: string;
}

const CoachingSummarySection: React.FC<ICoachingSummarySectionProps> = ({ summaryImage }) => {
	const { server, totalPrice, coachingParams, coupon, basePrice } = useSelector((state: AppState) => state.payment);
	const { coachRank, numberOfHours, coachingType, coachLines, isLoading } = useSelector(
		(state: AppState) => state.general,
	);
	const { username } = useSelector((state: AppState) => state.auth);
	const [couponCode, setCouponName] = useState<string>('');
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const handlePurchaseClick = () => {
		if (username === undefined) {
			return navigate(`/login`);
		}
		if (coachingParams) {
			const coachingRankMultiplier = coachingParams.rankOfCoach.find((r) => r.name === coachRank);
			const coachingServerMultiplier = server;
			const coachingTypeMultiplier = coachingParams.type.find((t) => t.name === coachingType);

			if (totalPrice && coachingRankMultiplier && coachingServerMultiplier && coachingTypeMultiplier) {
				const newOrder: CoachingOrder = {
					type: 'coaching',
					totalPrice: totalPrice,
					numberOfHours: numberOfHours,
					roles: coachLines,
					rankOfCoach: coachingRankMultiplier,
					serverMultiplier: {
						name: coachingServerMultiplier.abbreviation,
						multiplier: coachingServerMultiplier.multiplier,
					},
					server: coachingServerMultiplier.abbreviation,
					coachingType: coachingTypeMultiplier,
					currency: 'EUR',
					basePrice: basePrice,
					couponId: coupon?.id,
				};
				thunkDispatch(loadLoadingScreen(true));
				sessionStorage.setItem('cart', JSON.stringify(newOrder));
				navigate('/cart');
			}
		}
	};

	const validatePurchaseButton = () => {
		return (
			coachRank !== undefined &&
			server !== undefined &&
			numberOfHours !== undefined &&
			numberOfHours > 0 &&
			coachLines.length > 0 &&
			coachingType !== undefined
		);
	};

	const isDisabled = validatePurchaseButton();

	const handleCouponSubmit = async () => {
		if (coupon === undefined) {
			try {
				const response = await getCouponByCode(couponCode);
				if (response && response.body) {
					thunkDispatch(dispatchUpdateCoupon(response.body));
				}
				toast.success('Coupon applied');
				setCouponName('');
			} catch (err) {
				toast.error('Invalid coupon');
			}
		} else {
			toast.error('Coupon already applied');
		}
	};

	return (
		<div className="container">
			<div className="summary-order">
				<CouponBanner type="coaching" />
				<div className="summary-order__price">
					<FinalPrice totalPrice={totalPrice} />
					<h3>Discount for coupon?</h3>
					<CouponInput
						handleCouponSubmit={handleCouponSubmit}
						coupon={couponCode}
						setCoupon={(value) => setCouponName(value)}
					/>
					{isLoading ? (
						<Spinner />
					) : (
						<Button
							trigger={() => {
								handlePurchaseClick();
							}}
							type="button"
							variant="purchase"
							isDisabled={!isDisabled}
						>
							PURCHASE
						</Button>
					)}
				</div>
				<div className="summary-order__card">
					<img src={summaryImage} />
					<h3>YOUR COACHING SUMMARY:</h3>
					<div className="summary-order__parameters">
						<div className="parameters">
							{coupon && <p>COUPON:</p>}
							<p>REGION:</p>
							<p>TYPE OF COACHING:</p>
							<p>RANK OF COACH:</p>
							<p>NUMBER OF HOURS:</p>
							<p>SELECTED LINES:</p>
						</div>
						<div className="values">
							{coupon && (
								<p>
									{coupon.displayName} -{coupon.offPercentage}%
								</p>
							)}
							<p>{server?.name.toLocaleUpperCase()}</p>
							<p>{coachingType.toLocaleUpperCase()}</p>
							<p>{coachRank.toLocaleUpperCase()}</p>
							<p>{numberOfHours}</p>
							<p>
								{coachLines.map((l, index) => {
									let line = l.toLocaleUpperCase();
									if (index !== coachLines.length - 1) {
										line += ', ';
									}
									return line;
								})}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="questions">
				<img src={amumu} alt="Sad Amumu :( " />
				<p>
					Questions? <br /> Talk to our support team!
				</p>
			</div>
			<div className="payment-providers">
				<h2>Payment providers:</h2>
				<div className="payment-providers__img">
					<img src={paypal} />
					<img src={visa} />
					<img src={mastercard} />
				</div>
			</div>
		</div>
	);
};

export default CoachingSummarySection;
