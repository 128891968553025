import React from 'react';
import Backdrop from './Backdrop';
import Spinner from './Spinner';

interface ILoadingBackdrop {
	isMain: boolean;
}

const LoadingBackdrop: React.FC<ILoadingBackdrop> = ({ children, isMain }) => {
	return (
		<Backdrop isMain={isMain}>
			<Spinner />
			{children}
		</Backdrop>
	);
};

export default LoadingBackdrop;
