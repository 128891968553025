import React from 'react';
import { CoachingOrder } from 'types/order';

interface ICoachingCartOrderInfo {
	order: CoachingOrder;
}

const CoachingCartOrderInfo: React.FC<ICoachingCartOrderInfo> = ({ order }) => {
	return (
		<div className="cart-order-info cart-order-info--cart">
			<p>
				Type of coaching: <span>{order.coachingType.name}</span>
			</p>
			<p>
				Number of hours: <span>{order.numberOfHours}</span>
			</p>
			<p>
				Rank of coach: <span>{order.rankOfCoach.name}</span>
			</p>
			<p>
				Server: <span>{order.server}</span>
			</p>
			{order.roles.length > 0 && (
				<p>
					Roles:{' '}
					<span>
						{order.roles.map((r, index) => {
							if (index < order.roles.length - 1) {
								return `${r}, `;
							}
							return `${r} `;
						})}
					</span>
				</p>
			)}
		</div>
	);
};

export default CoachingCartOrderInfo;
