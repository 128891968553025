import { NetWinsOrder } from '../types/order';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const CREATE_NETWINS_ORDER: RequestMetadata = {
	path: '/Orders/netwins',
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

type CreateNetwinsOrderRequestBody = NetWinsOrder;

export const createNetwinsOrder = async (order: NetWinsOrder): Promise<RequestResponse<undefined>> => {
	return await getRequest<CreateNetwinsOrderRequestBody>(CREATE_NETWINS_ORDER, {
		body: order,
	});
};

export default createNetwinsOrder;
