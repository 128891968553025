import React, { useState } from 'react';
import { Champion } from '../types/general';
import { LOL_SERVER_URL } from '../../../api/apiConfig';
import clsx from 'clsx';
interface IChampionTileProps {
	isActive?: boolean;
	championData: Champion;
	handleChampionTileClick: () => void;
}

const ChampionTile: React.FC<IChampionTileProps> = ({ isActive, championData, handleChampionTileClick }) => {
	const [isNameVisible, setIsNameVisible] = useState<boolean>(false);

	return (
		<div
			onClick={() => handleChampionTileClick()}
			onMouseEnter={() => setIsNameVisible(true)}
			onMouseLeave={() => setIsNameVisible(false)}
			className={clsx({ active: isActive }, 'champion-tile')}
		>
			<p className={clsx({ visible: isNameVisible }, 'champion-tile__name')}>{championData.name}</p>
			<img
				src={`${LOL_SERVER_URL}/latest/plugins/rcp-be-lol-game-data/global/default/v1/champion-icons/${championData.id}.png`}
			/>
		</div>
	);
};

export default ChampionTile;
