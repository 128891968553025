import {
	LOAD_LOADING_SCREEN,
	LOAD_BACKDROP,
	UPDATE_PICKED_CHAMPIONS,
	UPDATE_SELECTED_ROLES,
	UPDATE_NUMBER_OF_GAMES,
	MasterPoints,
	UPDATE_NUMBER_OF_MASTER_POINTS,
	UPDATE_NUMBER_OF_MASTER_STARTING_POINTS,
	UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS,
	UPDATE_COACH_RANK,
	UPDATE_NUMBER_OF_HOURS,
	UPDATE_COACH_LINES,
	UPDATE_COACHING_TYPE,
	UPDATE_SERVICE_TYPE,
	SET_COOKIE_VISIBILITY,
	SET_POPUP,
} from '../types/generalTypes';
import { AppActions } from '../root';
import { Popup } from 'types/general';

export const loadLoadingScreen = (isLoading: boolean): AppActions => ({
	type: LOAD_LOADING_SCREEN,
	payload: isLoading,
});

export const loadBackdrop = (isLoading: boolean): AppActions => ({
	type: LOAD_BACKDROP,
	payload: isLoading,
});

export const updatePickedChampions = (champions: string[] | undefined): AppActions => ({
	type: UPDATE_PICKED_CHAMPIONS,
	payload: champions,
});

export const updateSelectedRoles = (roles: string[] | undefined): AppActions => ({
	type: UPDATE_SELECTED_ROLES,
	payload: roles,
});

export const updateNumberOfGames = (number: number): AppActions => ({
	type: UPDATE_NUMBER_OF_GAMES,
	payload: number,
});

export const updateNumberOfMasterPoints = (points: MasterPoints): AppActions => ({
	type: UPDATE_NUMBER_OF_MASTER_POINTS,
	payload: points,
});

export const updateNumberOfMasterStartingPoints = (pointsValue: number): AppActions => ({
	type: UPDATE_NUMBER_OF_MASTER_STARTING_POINTS,
	payload: pointsValue,
});

export const updateNumberOfMasterDesiredPoints = (pointsValue: number): AppActions => ({
	type: UPDATE_NUMBER_OF_MASTER_DESIRED_POINTS,
	payload: pointsValue,
});

export const updateNumberOfHours = (hours: number): AppActions => ({
	type: UPDATE_NUMBER_OF_HOURS,
	payload: hours,
});

export const updateRankOfCoach = (rank: string): AppActions => ({
	type: UPDATE_COACH_RANK,
	payload: rank,
});

export const updateCoachLines = (lines: string[]): AppActions => ({
	type: UPDATE_COACH_LINES,
	payload: lines,
});

export const updateCoachingType = (coachingType: string): AppActions => ({
	type: UPDATE_COACHING_TYPE,
	payload: coachingType,
});

export const updateServiceType = (serviceType: string): AppActions => ({
	type: UPDATE_SERVICE_TYPE,
	payload: serviceType,
});

export const setCookieVisibility = (isVisible: boolean): AppActions => ({
	type: SET_COOKIE_VISIBILITY,
	payload: isVisible,
})

export const setPopup = (popup: Popup | undefined): AppActions => ({
	type: SET_POPUP,
	payload: popup
})
