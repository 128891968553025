import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { APIOrder } from '../types/order';

const GET_ORDERS: RequestMetadata = {
	path: '/Orders/',
	method: 'GET',
	headers: {},
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

type GetOrdersResponseBody = APIOrder[];

export const getOrders = async (): Promise<RequestResponse<GetOrdersResponseBody>> => {
	return await getRequest<undefined, GetOrdersResponseBody>(GET_ORDERS);
};

export default getOrders;
