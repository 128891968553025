import React from 'react';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

interface IPopupProps {
	header: string;
	content: string;
	onAccept: () => void;
	onCancel: () => void;
}

const Popup: React.FC<IPopupProps> = ({ header, content, onAccept, onCancel }) => {
	const wrapperRef = React.createRef<HTMLDivElement>();
	useOutsideAlerter(wrapperRef as React.MutableRefObject<HTMLDivElement>, () => onCancel());

	return (
		<div className="popup-wrapper">
			<div ref={wrapperRef} className="popup">
				<div className="popup__header">
					<span className="material-icons">notifications</span>
					<h2>{header}</h2>
				</div>
				<p>{content}</p>
				<div className="popup__footer">
					<button className="footer-button footer-button--cancel" onClick={() => onCancel()}>
						Cancel
					</button>
					<button className="footer-button footer-button--accept" onClick={() => onAccept()}>
						Accept
					</button>
				</div>
				<button className="popup-close" onClick={() => onCancel()}>
					<span className="material-icons">close</span>
				</button>
			</div>
		</div>
	);
};

export default Popup;
