import React from 'react';
import clsx from 'clsx';
interface ISpinnerProps {
	isFull?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({ isFull }) => (
	<div className={clsx({ full: isFull }, 'spinner')}>
		<div className="circle one"></div>
		<div className="circle two"></div>
		<div className="circle three"></div>
	</div>
);

export default Spinner;
