import React, { useState } from 'react';
import CoverPhoto from './CoverPhoto';
const landinglogo = require('../../images/global/landinglogo.png');
import clsx from 'clsx';
import { useEffect } from 'react';
interface IBrandCoverProps {
	isFull?: boolean;
	className?: string;
	filename: string;
}

const BrandCover: React.FC<IBrandCoverProps> = ({ children, isFull, filename, className }) => {
	const [isClient, setClient] = useState(false);

	useEffect(() => {
		setClient(true);
	}, []);

	if (!isClient) {
		return null;
	}

	return (
		<section className={clsx({ fullScreen: isFull }, 'brand-cover', className ?? '')}>
			<CoverPhoto filename={filename} alt="brand-cover-bg" />
			<img src={landinglogo} className="brand-cover__header" />
			<div className="brand-cover__content">{children}</div>
		</section>
	);
};

export default BrandCover;
