import React from 'react';
import { Boosters } from '../types/general';
import { BoosterTile } from './';

interface IBoostersTilesListProps {
	boostersList?: Boosters;
}

const BoostersTilesList: React.FC<IBoostersTilesListProps> = ({ boostersList }) => {
	const renderBoostersTiles = () => {
		return boostersList!.boosters.map((booster) => {
			return <BoosterTile key={booster.id} booster={booster} />;
		});
	};

	return <div className="boosters__tiles">{renderBoostersTiles()}</div>;
};

export default BoostersTilesList;
