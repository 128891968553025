import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadBackdrop } from '../../redux/actions/generalActions';
import { AppState, ThunkGlobalDispatch } from '../../redux/root';
import clsx from 'clsx';
interface IBackdropProps {
	isMain: boolean;
}

const Backdrop: React.FC<IBackdropProps> = ({ children, isMain }) => {
	const { isBackdropActive } = useSelector((state: AppState) => state.general);
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	useEffect(() => {}, []);

	return (
		<div
			className={clsx({ active: isBackdropActive }, { main: isMain }, 'backdrop')}
			onMouseDown={(e) => {
				if (!isMain) {
					e.stopPropagation();
					thunkDispatch(loadBackdrop(false));
					document.body.style.overflow = 'auto';
				}
			}}
		>
			{children}
		</div>
	);
};

export default Backdrop;
