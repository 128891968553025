import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { LpGainAPIMultiplier } from '../types/general';

const GET_LP_GAIN_PRICING: RequestMetadata = {
	path: '/data/pricing/multipliers/pricing-lp-gain.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetLpGainPricingResponseBody = LpGainAPIMultiplier;

export const getStartingLpPricing = async (): Promise<RequestResponse<GetLpGainPricingResponseBody>> => {
	return await getRequest<undefined, GetLpGainPricingResponseBody>({ ...GET_LP_GAIN_PRICING });
};

export default getStartingLpPricing;
