export const API_BASE_URL = process.env.API_URL;
export const DATA_SERVER_URL = 'https://data.bamboosting.net';
export const LOL_SERVER_URL = 'https://raw.communitydragon.org';

export const STATUS_CODE_OK = 200;
export const STATUS_CODE_CREATED = 201;

export const AUTH_HEADER = 'Authorization';

export interface RequestMetadata {
	path: string;
	method: string;
	headers: Record<string, string>;
	shouldAuth: boolean;
	hasResponseBody: boolean;
	isFile?: boolean;
	isDataServer: boolean;
	isLolApi: boolean;
	credentials?: boolean;
}

export interface RequestParameters<B> {
	body?: B;
	path?: Map<string, string>;
	query?: Map<string, string>;
}

export interface RequestResponse<B> {
	statusCode: number;
	headers?: Headers;
	body?: B;
}
