import { LeagueOrder } from '../types/order';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const CREATE_LEAGUE_ORDER: RequestMetadata = {
	path: '/Orders/league',
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

type CreateLeagueOrderRequestBody = LeagueOrder;

export const createLeagueOrder = async (order: LeagueOrder): Promise<RequestResponse<undefined>> => {
	return await getRequest<CreateLeagueOrderRequestBody>(CREATE_LEAGUE_ORDER, {
		body: order,
	});
};

export default createLeagueOrder;
