import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActivation } from '../../api/requests/requestHandler';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import Spinner from 'components/shared/Spinner';
import BrandCover from 'components/shared/BrandCover';
import { toast } from 'react-toastify';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IActivationProps extends RouteComponentProps {
	token?: string;
}

const Activation: React.FC<IActivationProps> = ({ token }) => {
	const [message, setMessage] = useState<string>();
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading } = useSelector((state: AppState) => state.general);

	const activateByToken = async () => {
		if (token) {
			try {
				await authActivation(token);
				setMessage('Activation successful! You can now login.');
				toast.success('Activation successful!');
			} catch (err) {
				console.log(err);
				setMessage('Invalid token.');
			} finally {
				thunkDispatch(loadLoadingScreen(false));
			}
		}
	};

	useEffect(() => {
		thunkDispatch(loadLoadingScreen(true));
		activateByToken();
	}, []);

	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" isFull>
				{isLoading && <Spinner />}
				{!isLoading && message !== undefined && <h1 className="activation-message">{message}</h1>}
			</BrandCover>
		</LayoutRoot>
	);
};

export default Activation;
