import React from 'react';
import { Transition } from 'react-transition-group';

interface IAnimatedComponentProps {
	in: boolean;
	className?: string;
}

const defaultStyle = {
	transition: `transform 350ms, opacity 350ms ease`,
	opacity: 1,
};

const transitionStyles = {
	entering: { transform: 'scale(0.5)', opacity: 0 },
	entered: { transform: 'scale(1.0)', opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 },
	unmounted: {},
};

const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) => obj[key];

const AnimatedComponent: React.FC<IAnimatedComponentProps> = ({ in: inProp, children, className }) => (
	<Transition
		in={inProp}
		timeout={{
			appear: 300,
			enter: 300,
			exit: 300,
		}}
	>
		{(state) => {
			const _transitionStyles = getKeyValue(transitionStyles)(state);
			return (
				<div
					style={{
						...defaultStyle,
						..._transitionStyles,
					}}
					className={className}
				>
					{children}
				</div>
			);
		}}
	</Transition>
);

export default AnimatedComponent;
