import { RequestMetadata, RequestResponse } from '../apiConfig';
import { getRequest } from '../RequestFactory';
import { Boosters } from 'types/general';

const GET_BOOSTERS: RequestMetadata = {
	path: '/data/boosters/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetBoostersResponseBody = Boosters;

export const getBoosters = async (): Promise<RequestResponse<GetBoostersResponseBody>> => {
	return await getRequest<undefined, GetBoostersResponseBody>({ ...GET_BOOSTERS });
};

export default getBoosters;
