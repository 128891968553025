import { ActivationResponseBody, TokenRequestBody } from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_ACTIVATION: RequestMetadata = {
	path: '/Accounts/verify-email',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authActivation = async (token: string): Promise<RequestResponse<ActivationResponseBody>> => {
	return await getRequest<TokenRequestBody, ActivationResponseBody>(AUTH_ACTIVATION, {
		body: {
			token: token,
		},
	});
};

export default authActivation;
