import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface ITOUProps extends RouteComponentProps {}

const TOU: React.FC<ITOUProps> = () => {
	return (
		<LayoutRoot>
			<div className="legal legal--terms-of-use container">
				<h1>Terms of use</h1>
				<h2>Please read our terms and conditions before ordering any type of service at Bamboosting.net.</h2>
				<h3>Terms of Service ("Terms")</h3>
				<p>
					Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the{' '}
					<Link to="/">https://www.bamboosting.net/</Link> website (the "Service") operated by Bamboosting.net ("us",
					"we", or "our").
					<br />
					Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
					These Terms apply to all visitors, users and others who access or use the Service.
					<br />
					By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the
					terms then you may not access the Service.
				</p>
				<h3>Purchases</h3>
				<p>
					If you wish to purchase any product or service made available through the Service ("Purchase"), you may be
					asked to supply certain information relevant to your Purchase including, without limitation, your credit card
					number, the expiration date of your credit card, your billing address, and your shipping information.
					<br />
					You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
					method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and
					complete.
					<br />
					By submitting such information, you grant us the right to provide the information to third parties for
					purposes of facilitating the completion of Purchases.
					<br />
					We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited
					to: product or service availability, errors in the description or price of the product or service, error in
					your order or other reasons.
					<br />
					We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is
					suspected.
				</p>
				<h3>Availability, Errors and Inaccuracies</h3>
				<p>
					We are constantly updating our offerings of products and services on the Service. The products or services
					available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience
					delays in updating information on the Service and in our advertising on other web sites.
					<br />
					We cannot and do not guarantee the accuracy or completeness of any information, including prices, product
					images, specifications, availability, and services. We reserve the right to change or update information and
					to correct errors, inaccuracies, or omissions at any time without prior notice.
				</p>
				<h3>Accounts</h3>
				<p>
					When you create an account with us, you must provide us information that is accurate, complete, and current at
					all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
					your account on our Service.
					<br />
					You are responsible for safeguarding the password that you use to access the Service and for any activities or
					actions under your password, whether your password is with our Service or a third-party service.
					<br />
					You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware
					of any breach of security or unauthorized use of your account.
					<br />
					You may not use as a username the name of another person or entity or that is not lawfully available for use,
					a name or trademark that is subject to any rights of another person or entity other than you without
					appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
				</p>
				<h3>Customer Duties When Using Or Planning To Use Our Service</h3>
				<p>
					Bamboosting.net is not associated with Riot Games or any unauthorized entity in any way, shape, or form.
					Bamboosting.net, warns any customer or potential customer to refrain from violating, infringing, or making any
					illegal action in regards to the intellectual property rights of Riot Games or any unauthorized entity.
					<br />
					By using our Site or any Service included under Bamboosting.net, You accept and have made yourself aware of
					all our Terms of use, and You are aware that by purchasing any Service under Bamboosting.net you know what you
					are paying for, and that the Service you are paying for matches your in-Game information.
					<br />
					You accept that by buying our Service you are aware that you cannot dispute any purchase with Bamboosting.net
					after the Service is done or the service has been partially completed, and that you as a customer cannot
					violate the rules of ChargeBacks stipulated in any payment method provided by Bamboosting.net.
					<br />
					You, the customer, accept that if you open a claim after the order has gone through or the service has started
					or been completed, you are in direct breach of Bamboosting's terms of use, and legally bound to either close
					the claim or pay back the same amount in addition to a fee determined by Bamboosting.net, no less than $50 and
					no more than three times the original amount if the order placed cost more than $50. Should you fail to abide
					by either option, you, the customer, accept full liability in a court of law as determined by the European
					Trade Laws.
					<br />
					You accept that once your purchase has gone through and is marked as "In Progress", or a booster or coach has
					been assigned, you are no longer eligible to receive a refund. If service is not completed by Bamboosting.net
					you have the right to receive store credit fitting what is missing from your service.
				</p>
				<h3>Intellectual Property</h3>
				<p>
					The Service and its original content, features and functionality are and will remain the exclusive property of
					Bamboosting.net and its licensors. The Service is protected by copyright, trademark, and other laws of both
					Poland and foreign countries. Our trademarks and trade dress may not be used in connection with any product or
					service without the prior written consent of Bamboosting.net
				</p>
				<h3>Refund Policy</h3>
				<p>
					Due to the nature of Bamboosting's products (non-tangible digital goods), it is not possible to "return" the
					product therefore we DO NOT offer a refund after purchase is made. If your order has not started you are
					eligible for a partial refund (55%) or store credit for the amount paid to us.
					<br />
					Once the service has started, you are no longer eligible to receive a refund. If service is not completed by
					Bamboosting.net you have the right to receive store credit fitting what is missing from your service.
					<br />
					If we are not able to complete the purchase until the season ends, we are only able to refund the remaining
					part of the order
				</p>
				<h3>Links To Other Websites</h3>
				<p>
					Our Service may contain links to third-party web sites or services that are not owned or controlled by
					Bamboosting.net
					<br />
					Bamboosting.net has no control over, and assumes no responsibility for, the content, privacy policies, or
					practices of any third party web sites or services. You further acknowledge and agree that Bamboosting.net
					shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
					caused by or in connection with use of or reliance on any such content, goods or services available on or
					through any such web sites or services.
					<br />
					We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
					services that you visit.
				</p>
				<h3>Termination</h3>
				<p>
					We may terminate or suspend your account immediately, without prior notice or liability, for any reason
					whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the
					Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the
					Service.
				</p>
				<h3>Governing Law</h3>
				<p>
					These Terms shall be governed and construed in accordance with the laws of Poland, without regard to its
					conflict of law provisions.
					<br />
					Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
					If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
					these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our
					Service, and supersede and replace any prior agreements we might have between us regarding the Service.
				</p>
				<h3>Changes</h3>
				<p>
					We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
					material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes
					a material change will be determined at our sole discretion.
					<br />
					By continuing to access or use our Service after those revisions become effective, you agree to be bound by
					the revised terms. If you do not agree to the new terms, please stop using the Service.
				</p>
				<h3>Contact Us</h3>
				<p>If you have any questions about these Terms, please contact us. Email address: info@bamboosting.net</p>
			</div>
		</LayoutRoot>
	);
};

export default TOU;
