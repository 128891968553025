import { Link } from '@reach/router';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitForm } from '../../api/requests/requestHandler';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import Footer from 'components/shared/Footer';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
const background = require('src/images/contact/contact_bg.jpg');
const bambooLeft = require('src/images/landing/bamboo-left.png');
const bambooRight = require('src/images/landing/bamboo-right.png');
const party = require('src/images/contact/party.png');
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IContactProps extends RouteComponentProps {}

type FormValues = {
	providedEmail: string;
	subject: string;
	message: string;
};

const Contact: React.FC<IContactProps> = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading } = useSelector((state: AppState) => state.general);
	const { username } = useSelector((state: AppState) => state.auth);
	const [message, setMessage] = useState<string>();
	const password = useRef();
	const { register, handleSubmit, errors, formState, watch } = useForm({ mode: 'onChange' });
	password.current = watch('password', '');

	const onSubmit = (data: FormValues) => {
		thunkDispatch(loadLoadingScreen(true));
		submitForm(data.providedEmail, data.subject, data.message).then((res) => {
			if (res.statusCode === 200) {
				setMessage('Form submitted successfully.');
			}
			thunkDispatch(loadLoadingScreen(false));
		});
	};

	const renderFormBody = () => {
		return (
			<>
				<label>
					E-MAIL
					{errors.email && <span> {errors.email.message}</span>}
				</label>
				<input
					name="providedEmail"
					ref={register({
						required: 'Please specify email.',
						pattern: {
							value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: 'Invalid email.',
						},
					})}
					required
					type="email"
					className="login-input"
					placeholder="E-mail"
				/>
				<label>
					SUBJECT
					{errors.subject && <span> {errors.subject.message}</span>}
				</label>
				<input
					name="subject"
					ref={register({
						required: 'Please specify subject.',
					})}
					required
					type="text"
					className="login-input"
					placeholder="Subject"
				/>
				<label>
					MESSAGE
					{errors.message && <span> {errors.message.message}</span>}
				</label>
				<textarea
					name="message"
					ref={register({
						required: 'Please write a message.',
					})}
					required
					className="login-input"
					placeholder="Message"
				/>
				<Button isDisabled={!formState.isValid} type="submit" variant="sign-in">
					Send
				</Button>
			</>
		);
	};

	const renderContentBasedOnMessage = () => {
		if (message) {
			return <h2>{message}</h2>;
		}
		return renderBodyBasedOnAuth();
	};

	const renderBodyBasedOnAuth = () => {
		if (!username) {
			return (
				<>
					<h2>
						<span>Login</span> to access our support:
					</h2>
					<Link to="/login" className="btn btn__sign-in" type="button">
						Login
					</Link>
				</>
			);
		}
		return renderBodyBasedOnLoading();
	};

	const renderBodyBasedOnLoading = () => {
		if (isLoading) {
			return <Spinner />;
		}
		return renderFormBody();
	};

	return (
		<LayoutRoot isMenuTransparent>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<img className="services-cover__bamboo" src={bambooLeft} />
				<img className="services-cover__bamboo" src={bambooRight} />
				<div className="site-content container">
					<div className="services__header">
						<h1>CONTACT</h1>
						<h3>IF YOU HAVE ANY QUESTIONS, JUST LET US KNOW</h3>
						<p>We will do our best to find a solution for your problem as soon as possible.</p>
						<img className="contact-img" src={party} alt="contact" />
					</div>
					<form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
						{renderContentBasedOnMessage()}
					</form>
				</div>
			</div>
			<Footer />
		</LayoutRoot>
	);
};

export default Contact;
