import React from 'react';
import CouponBanner from '../../coupon/CouponBanner';
import ExtrasSection from '../UI/ExtrasSection';

interface IParamsExtrasProps {
	headlineNumber?: string;
}

const ParamsExtras: React.FC<IParamsExtrasProps> = ({ headlineNumber }) => (
	<>
		<div className="boosting-params__extras">
			<div className="params-header">
				<span>{headlineNumber ?? '07'}</span>
				<h2>Extras</h2>
			</div>
			<ExtrasSection />
			<CouponBanner type="boosting" />
		</div>
	</>
);

export default ParamsExtras;
