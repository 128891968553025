import { Link, RouteComponentProps } from '@reach/router';
import * as React from 'react';
import LayoutRoot from './LayoutRoot';
interface I404Props extends RouteComponentProps {}

const amumu = require('../../images/order-summary/amumu.png');

const NotFoundPage: React.FC<I404Props> = () => (
	<LayoutRoot>
		<div className="not-found-wrapper">
			<img src={amumu} alt="amumu" />
			<h1>404 - NOT FOUND</h1>
			<p>This subpage doesn&#39;t exist... the sadness.</p>
			<Link to="/">&larr; Homepage</Link>
		</div>
	</LayoutRoot>
);

export default NotFoundPage;
