import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import authValidateResetToken from '../../api/requests/auth/authValidateResetToken';
import { authResetPassword } from '../../api/requests/requestHandler';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import BrandCover from 'components/shared/BrandCover';
import { toast } from 'react-toastify';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IResetPasswordProps extends RouteComponentProps {
	token?: string;
}

type FormValues = {
	password: string;
	confirmPassword: string;
};

const ResetPassword: React.FC<IResetPasswordProps> = ({ token }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading } = useSelector((state: AppState) => state.general);
	const { register, handleSubmit, errors, formState, watch } = useForm({ mode: 'onChange' });
	const [message, setMessage] = useState<string>();
	const password = useRef();
	const [showPassword, setShowPassword] = useState<boolean>(false);

	password.current = watch('password', '');

	const onSubmit = async (data: FormValues) => {
		thunkDispatch(loadLoadingScreen(true));
		try {
			if (token) {
				const response = await authResetPassword(data.password, data.confirmPassword, token);
				setMessage(response.body?.message);
				toast.success('Password reset succesful.');
			}
		} catch (err) {
			toast.error('Error during password reset.');
		} finally {
			thunkDispatch(loadLoadingScreen(false));
		}
	};

	const validateResetToken = async () => {
		try {
			if (token) {
				await authValidateResetToken(token);
			}
		} catch (err) {
			setMessage('Token is invalid');
		} finally {
			thunkDispatch(loadLoadingScreen(false));
		}
	};

	useEffect(() => {
		thunkDispatch(loadLoadingScreen(true));
		validateResetToken();
	}, []);

	const renderFormBody = () => (
		<>
			<h3>Reset password form</h3>
			<label>NEW PASSWORD {errors.password && <span>{errors.password.message}</span>}</label>
			<div className="pass-wrapper">
				<input
					name="password"
					ref={register({
						required: 'Please specify a password.',
						minLength: {
							value: 8,
							message: 'Password must have at least 8 characters',
						},
					})}
					required
					type={showPassword ? 'text' : 'password'}
					className="login-input"
					placeholder="Password"
				/>
				<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
					{showPassword ? 'visibility_off' : 'visibility'}
				</span>
			</div>
			<label>RETYPE PASSWORD {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}</label>
			<div className="pass-wrapper">
				<input
					name="confirmPassword"
					required
					ref={register({
						required: true,
						validate: (value) => value === password.current || "Passwords don't match.",
					})}
					type={showPassword ? 'text' : 'password'}
					className="login-input"
					placeholder="Retype Password"
				/>
				<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
					{showPassword ? 'visibility_off' : 'visibility'}
				</span>
			</div>
			<Button type="submit" variant="green">
				Reset
			</Button>
		</>
	);

	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" isFull>
				<form className="password-form" onSubmit={handleSubmit(onSubmit)}>
					{!message && (isLoading ? <Spinner /> : renderFormBody())}
					{message !== undefined && <h1>{message}.</h1>}
				</form>
			</BrandCover>
		</LayoutRoot>
	);
};

export default ResetPassword;
