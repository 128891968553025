import authLogin from '../../api/requests/auth/authLogin';
import { DecodedJWT, LoginCredentialsBody, LoginResponseBody } from 'types/auth';
import { AppActions, ThunkGlobalDispatch, ThunkResult } from '../root';
import { AUTH_SUCESS } from '../types/authTypes';
import { loadLoadingScreen } from './generalActions';
import { authLogout, authRefreshToken } from '../../api/requests/requestHandler';
import { clearCookie, getCookie, setCookie } from '../../utils/cookies';

const parseJWT = (jwt: string): DecodedJWT | undefined => {
	if (jwt !== undefined) {
		try {
			return JSON.parse(atob(jwt.split('.')[1]));
		} catch (e) {
			return undefined;
		}
	}
	return undefined;
};

export const dispatchLogin = (loginCredentials: LoginCredentialsBody, shouldRemember: boolean): ThunkResult => async (
	dispatch: ThunkGlobalDispatch,
) => {
	dispatch(loadLoadingScreen(true));
	const response = await authLogin(loginCredentials.email, loginCredentials.password)
	if (response.body?.accessToken) {
		const decodedToken = parseJWT(response.body.accessToken);
			if (response.body && decodedToken) {
				const expirationDate = new Date(decodedToken.exp * 1000);
				const timeToExpiration = expirationDate.getTime() - new Date().getTime();
				if (response.body.refreshToken) {
					if (shouldRemember) {
						setCookie('refreshTokenBB', response.body.refreshToken, 30);
					}
					sessionStorage.setItem('refreshToken', response.body.refreshToken);
				}
				sessionStorage.setItem('expirationDate', String(decodedToken.exp * 1000));
				dispatch(setAuthData(response.body));
				dispatch(dispatchTokenExpiration(timeToExpiration));
			}
		dispatch(loadLoadingScreen(false));
	}
};

export const dispatchLogout = (): ThunkResult => async (dispatch: ThunkGlobalDispatch) => {
	const blankAuthData: LoginResponseBody = {
		accessToken: undefined,
		id: undefined,
		role: undefined,
		refreshToken: undefined,
		username: undefined,
	};
	await authLogout();
	dispatch(setAuthData(blankAuthData));
	clearCookie('refreshTokenBB');
	sessionStorage.removeItem('refreshToken');
	sessionStorage.removeItem('expirationDate');
};

export const dispatchTokenExpiration = (expirationTime: number): ThunkResult => async (
	dispatch: ThunkGlobalDispatch,
) => {
	const MIN_IN_MS = 1000 * 60;
	const minBeforeExpirationTime = expirationTime - 1 * MIN_IN_MS;

	setTimeout(() => {
		const token = sessionStorage.getItem('refreshToken');
		if (token) {
			dispatch(authRefresh(token));
		}
	}, minBeforeExpirationTime);
};



export const dispatchTryAutomaticLogin = (): ThunkResult => async (dispatch: ThunkGlobalDispatch) => {
	const storageToken = sessionStorage.getItem('refreshToken');
	const cookieToken = getCookie('refreshTokenBB');
	if (cookieToken) {
		dispatch(authRefresh(cookieToken));
	} else if (cookieToken === null && storageToken !== null) {
		dispatch(authRefresh(storageToken));
	}
};

const authRefresh = (token: string): ThunkResult => async (dispatch: ThunkGlobalDispatch) => {
	if (token) {
		authRefreshToken(token).then((response) => {
			if (response.body?.accessToken) {
				const decodedToken = parseJWT(response.body.accessToken);
				if (response.body && decodedToken) {
					const expirationDate = new Date(decodedToken.exp * 1000);
					const timeToExpiration = expirationDate.getTime() - new Date().getTime();
					if (response.body.refreshToken) {
						sessionStorage.setItem('refreshToken', response.body.refreshToken);
						if (getCookie('refreshTokenBB')) {
							setCookie('refreshTokenBB', response.body.refreshToken, 30);
						}
					}

					dispatch(setAuthData(response.body));
					dispatch(dispatchTokenExpiration(timeToExpiration));
				}
			}
		});
	}
};

export const setAuthData = (loginInfo: LoginResponseBody): AppActions => ({
	type: AUTH_SUCESS,
	payload: loginInfo,
});
