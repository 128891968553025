import React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import LeagueBoosting from './BoostingTypes/LeagueBoosting';
import DuoBoosting from './BoostingTypes/DuoBoosting';
import WinBoosting from './BoostingTypes/WinBoosting';
import PlacementBoosting from './BoostingTypes/PlacementBoosting';
import TypesBar from '../boosting/UI/TypesBar';
const background = require('src/images/global/champions_presentation.jpg');
const bambooLeft = require('src/images/landing/bamboo-left.png');
const bambooRight = require('src/images/landing/bamboo-right.png');
import SummarySection from './UI/SummarySection';
import Footer from 'components/shared/Footer';
import ChampionPicker from './UI/ChampionPicker';
import LayoutRoot from 'components/shared/LayoutRoot';
import { ORDER_TYPES } from '../../constants/general';

interface IBoostingProps extends RouteComponentProps {
	type?: string;
}

const Boosting: React.FC<IBoostingProps> = ({ type }) => {
	const renderBoostingInfo = () => {
		if (type) {
			switch (type.toLocaleUpperCase()) {
				case ORDER_TYPES.LEAGUE: {
					return <LeagueBoosting />;
				}
				case ORDER_TYPES.DUO: {
					return <DuoBoosting />;
				}
				case ORDER_TYPES.NETWINS: {
					return <WinBoosting />;
				}
				case ORDER_TYPES.PLACEMENT: {
					return <PlacementBoosting />;
				}
				default: {
					return <Redirect to="/404/" />;
				}
			}
		}
	};

	return (
		<LayoutRoot isMenuTransparent>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<img className="services-cover__bamboo" src={bambooLeft} />
				<img className="services-cover__bamboo" src={bambooRight} />
				<div className="site-content container">
					<div className="services__header">
						<h1>BOOSTING</h1>
						<h3>Customize your boosting session</h3>
						<p>
							Select main features of your boosting order. Feel free to select extras that will ensure the best boosting
							experience.
						</p>
					</div>
				</div>
				<TypesBar />
				{renderBoostingInfo()}
				<SummarySection summaryImage={background} />
				<Footer />
				<ChampionPicker />
			</div>
		</LayoutRoot>
	);
};

export default Boosting;
