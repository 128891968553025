import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRankOfCoach } from '../../../redux/actions/generalActions';
import { dispatchWithCoachingPriceCalculation } from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { CoachingPricing } from 'types/general';
import CoachRank from './CoachRank';
const master = require('../../../images/ranks/master.png');
const grandmaster = require('../../../images/ranks/grandmaster.png');
const challenger = require('../../../images/ranks/challenger.png');

interface ICoachRanks {
	coachingPricing: CoachingPricing;
}

const CoachRanks: React.FC<ICoachRanks> = ({ coachingPricing }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { coachRank } = useSelector((state: AppState) => state.general);

	const handleRankClick = (rank: string) => {
		thunkDispatch(dispatchWithCoachingPriceCalculation(updateRankOfCoach(rank), coachingPricing));
	};

	return (
		<div className="coach-ranks">
			<CoachRank
				isActive={coachRank === 'master'}
				handleRankClick={() => handleRankClick('master')}
				rankImg={master}
				coachRank="Master"
			/>
			<CoachRank
				isActive={coachRank === 'grandmaster'}
				handleRankClick={() => handleRankClick('grandmaster')}
				rankImg={grandmaster}
				coachRank="Grandmaster"
			/>
			<CoachRank
				isActive={coachRank === 'challenger'}
				handleRankClick={() => handleRankClick('challenger')}
				rankImg={challenger}
				coachRank="Challenger"
			/>
		</div>
	);
};

export default CoachRanks;
