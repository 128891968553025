import * as React from 'react';

import Button from 'components/shared/Button';
const forward = require('src/images/global/champions_presentation.jpg');
const backward = require('src/images/global/champions_fight.jpg');
const bambooLeft = require('src/images/landing/bamboo-left.png');
const bambooRight = require('src/images/landing/bamboo-right.png');
const qyiana = require('src/images/landing/qyiana.png');
import Footer from 'components/shared/Footer';
import StepsList from './UI/StepsList';
import BrandCover from 'components/shared/BrandCover';
import { navigate } from 'gatsby';
import AppearOnFocusComponent from '../animation/AppearOnFocusComponent';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface ILandingProps extends RouteComponentProps {}

const Landing: React.FC<ILandingProps> = () => {
	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" />
			<AppearOnFocusComponent>
				<section className="info">
					<div className="info__column">
						<div className="column-images">
							<img src={backward} />
							<img src={forward} />
						</div>
					</div>
					<div className="info__column">
						<div className="column-content">
							<h2>Boosting</h2>
							<p>
								If you are looking for high quality boosting services you have come to the right place. Our offer
								includes four different types of boosting and coaching carried out either by the booster alone, or in
								duo with the client.
							</p>
							<Button trigger={() => navigate('/boosting/league')} variant="green">
								BOOST ME
							</Button>
						</div>
					</div>
				</section>
			</AppearOnFocusComponent>
			<section className="steps">
				<StepsList />
				<img className="steps__image" src={bambooLeft} />
				<img className="steps__image--cover" src={qyiana} />
				<img className="steps__image" src={bambooRight} />
			</section>

			<Footer isFull />
		</LayoutRoot>
	);
};

export default Landing;
