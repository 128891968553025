type FAQEntry = {
	header: string;
	index?: number;
	content?: string;
};

export const faqEntries: FAQEntry[] = [
	{
		header: 'General Information'
	},
	{
		index: 1,
		header: 'What is Bamboosting.net, and why choose us?',
		content:
			"Bamboosting.net is the ultimate LoL boost, LoL coaching. It has flawless reputation in the industry due to it's premium quality of service that remains to be unmatched in the industry.",
	},
	{
		index: 2,
		header: 'Why is Bamboosting.net the ultimate LoL boost and LoL coaching provider?',
		content:
			'Bamboosting.net has the highest standards in terms of both LoL ELO boosting, and LoL ranked and smurf accounts. There is no other place online that offers these services at such a premium level while keeping the prices at a reasonably low level. If you are looking for an all-around professional and premium experience, Bamboosting.net is the way to go.',
	},
	{
		index: 3,
		header: 'How can I trust Bamboosting.net?',
		content:
			'Bamboosting.net is the number one company in terms of safety. We put a great emphasis on account safety, which is a base obligation when it comes to handling a high number of precious accounts. With the help of VPN and 256bit SSL encryption, Bamboosting.net is considered the safest and most trustworthy company in the LoL ELO boost market.',
	},
	{
		index: 4,
		header: 'Which regions are supported by Bamboosting.net?',
		content: 'We ELO boost, and coach accounts on several servers and regions of the game. These servers include Europe Nordic & East, Europe West, Russia and Turkey.'
	},
	{
		header: 'Payment Methods'
	},
	{
		index: 1,
		header: 'How do I know that my financial information is safe with Bamboosting.net?',
		content: 'Bamboosting.net does not handle directly financial information. Only our established payment gateway companies such as Paypal has access to sensitive financial data while paying for our services. By using the most secure and verified payment gateways, we ensure maximum safety for our users.'
	},
	{
		index: 2,
		header: 'Can I checkout as a guest at Bamboosting.net?',
		content: 'Unfortunetaly for now you cannot use our services without account registration.'
	},
	{
		index: 3,
		header: "What if I can't find a suitable payment method?",
		content: "Do not hesitate to contact us through contact form. Our experienced support staff is always ready to help and find a solution for you!"
	},
	{
		header: "BamBoosting"
	},
	{
		index: 1,
		header: 'What is LoL Boosting/ ELO boosting?',
		content: 'LoL boosting or ELO boosting refers to a virtual service provided in League of Legends. There are various methods of the service, and Bamboosting.net offers all of them in the highest quality. The essence of LoL boosting or ELO boosting relates to highly skilled (challenger) players. Bamboosting.net employs these players to offer their help to all League of Legends players intending to achieve the highest tiers of the game.'
	},
	{
		index: 2,
		header: 'Can I get my account banned for LoL boosting?',
		content: "By using Bamboosting.net's ELO boost services, the ban rate remains 0% both for solo and duo orders, as we use VPN for all orders. If you buy from an unprofessional provider, there is a chance of Riot notices you use ELO boost and first offenders receive a 14-day suspension. We recommend all ELO boost buyers to use the safest services provided by Bamboosting.net to evade any ban related issues in the future."
	},
	{
		index: 3,
		header: 'What is the type of ELO boost supported by Bamboosting.net?',
		content: 'Bamboosting.net offers boosting services in all aspects of the game. Our service list can be found on the main page.'
	},
	{
		index: 4,
		header: 'Can I specify champions or roles?',
		content: "Yes, both are possible, and you can select these details before finalizing your order."
	},
	{
		header: "Coaching",
	},
	{
		index: 1,
		header: "What is LoL coaching, and how does it work?",
		content: "LoL coaching is described to be the most beneficial ELO boost service. Throughout the process of LoL coaching, challenger players can be hired by League of Legends players looking to achieve the highest levels of the game. LoL coaching usually has the following mechanism. The challenger coach analyzes the game style of the buyer, then based on the buyer's preferences, they start to work together as the coach helps him with further insights. The goal is that after each coaching session, the buyer becomes a more skillful player with a better understanding of the game."
	},
	{
		index: 2,
		header: 'What are the prices of coaching?',
		content: 'In the coaching menu, we have our prices listed based on coach`s rank.'
	}
];
