import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

interface IStaticImageProps {
	filename: string;
	alt: string;
}

const StaticImage: React.FC<IStaticImageProps> = ({ filename, alt }) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					images: allFile {
						edges {
							node {
								relativePath
								name
								childImageSharp {
									fluid(maxWidth: 1903, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				const image = data.images.edges.find((n: any) => {
					return n.node.relativePath.includes(filename);
				});
				if (!image) {
					return null;
				}

				return <Img className="static-image" alt={alt} fluid={image.node.childImageSharp.fluid} />;
			}}
		/>
	);
};

export default StaticImage;
