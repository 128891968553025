import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_LOGOUT: RequestMetadata = {
	path: '/accounts/revoke-token',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

type revokeMsg = {
	message: string;
};

export const authLogout = async (): Promise<RequestResponse<revokeMsg>> => {
	return await getRequest<{}, revokeMsg>(AUTH_LOGOUT, {
		body: {},
	});
};

export default authLogout;
