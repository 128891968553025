import { RegisterRequestBody, RegisterResponseBody } from '../types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_REGISTER: RequestMetadata = {
	path: '/Accounts/register',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: false,
};

export const authRegister = async (
	email: string,
	username: string,
	password: string,
	discord: string,
	country: string,
	confirmPassword: string,
	acceptTerms: boolean,
	skype: string,
): Promise<RequestResponse<RegisterResponseBody>> => {
	return await getRequest<RegisterRequestBody, RegisterResponseBody>(AUTH_REGISTER, {
		body: {
			username,
			email,
			password,
			discord,
			skype,
			country,
			confirmPassword,
			acceptTerms,
		},
	});
};

export default authRegister;
