import React from 'react';
import Menu from './Menu';

interface IHeaderProps {
	isMenuTransparent?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ isMenuTransparent, children }) => {
	return (
		<header>
			<Menu isTransparent={isMenuTransparent} />
		</header>
	);
};

export default Header;
