import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCoachLines } from '../../../redux/actions/generalActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { CoachingPricing } from '../types/general';
import Line from './Line';
const top = require('../../../images/coaching/top.png');
const jungle = require('../../../images/coaching/jungle.png');
const mid = require('../../../images/coaching/mid.png');
const adc = require('../../../images/coaching/adc.png');
const support = require('../../../images/coaching/support.png');

interface ICoachingLinesProps {
	coachingPricing: CoachingPricing;
}

const CoachingLines: React.FC<ICoachingLinesProps> = ({ coachingPricing }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { coachLines } = useSelector((state: AppState) => state.general);

	const handleLineSelection = (line: string) => {
		const lines = [...coachLines];
		if (coachLines.includes(line)) {
			coachLines.splice(coachLines.indexOf(line), 1);
		} else {
			coachLines.push(line);
		}
		thunkDispatch(updateCoachLines(coachLines));
	};

	return (
		<div className="coaching-lines">
			<Line
				handleLineSelection={() => handleLineSelection('top')}
				isActive={coachLines.includes('top')}
				isOdd
				championImage={top}
				lineName="top"
			/>
			<Line
				handleLineSelection={() => handleLineSelection('jungle')}
				isActive={coachLines.includes('jungle')}
				championImage={jungle}
				lineName="jungle"
			/>
			<Line
				handleLineSelection={() => handleLineSelection('mid')}
				isActive={coachLines.includes('mid')}
				isOdd
				championImage={mid}
				lineName="mid"
			/>
			<Line
				handleLineSelection={() => handleLineSelection('adc')}
				isActive={coachLines.includes('adc')}
				championImage={adc}
				lineName="adc"
			/>
			<Line
				handleLineSelection={() => handleLineSelection('support')}
				isActive={coachLines.includes('support')}
				isOdd
				championImage={support}
				lineName="support"
			/>
		</div>
	);
};

export default CoachingLines;
