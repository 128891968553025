import React from 'react';

const background = require('src/images/faq/faq_bg.jpg');
const bambooLeft = require('src/images/landing/bamboo-left.png');
const bambooRight = require('src/images/landing/bamboo-right.png');
import Accordeon from './UI/Accordeon';
import Footer from 'components/shared/Footer';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';
import { faqEntries } from '../../config/faqEntries';
interface IFaqProps extends RouteComponentProps {}

const FAQ: React.FC<IFaqProps> = () => {
	return (
		<LayoutRoot isMenuTransparent>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<img className="services-cover__bamboo" src={bambooLeft} />
				<img className="services-cover__bamboo" src={bambooRight} />
				<div className="site-content container">
					<div className="services__header">
						<h1>FAQ</h1>
						<h3>Frequently asked questions</h3>
						<p>
							Answers to these questions will surely help you during exploration of our website. If you have any
							questions that were not addressed below feel free to send us a message via contact form.
						</p>
					</div>
					<div className="accordeon-list">
						{faqEntries.map((e, i) =>
							e.content && e.index ? (
								<Accordeon
									key={i * 1234 + 'aco'}
									isOdd={i % 2 !== 0}
									index={e.index}
									header={e.header}
									content={e.content}
								/>
							) : (
								<h2 key={i * 1234 + 'aco'}>{e.header}</h2>
							),
						)}
					</div>
				</div>
			</div>
			<Footer />
		</LayoutRoot>
	);
};

export default FAQ;
