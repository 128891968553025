import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { ServerAPIMultiplier } from '../types/general';

const GET_SERVER_PRICING: RequestMetadata = {
	path: '/data/pricing/multipliers/pricing-server.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetServerPricingResponseBody = ServerAPIMultiplier;

export const getServerPricing = async (): Promise<RequestResponse<GetServerPricingResponseBody>> => {
	return await getRequest<undefined, GetServerPricingResponseBody>({ ...GET_SERVER_PRICING });
};

export default getServerPricing;
