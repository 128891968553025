import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAccountById, updateAccountById } from '../../api/requests/requestHandler';
import { User as APIUser } from 'types/auth';
import Button from 'components/shared/Button';
import Footer from 'components/shared/Footer';
import LayoutRoot from 'components/shared/LayoutRoot';
const background = require('../../images/contact/contact_bg.jpg');
const bambooLeft = require('../../images/landing/bamboo-left.png');
const bambooRight = require('../../images/landing/bamboo-right.png');

interface IUserProps extends RouteComponentProps {
	userId?: string;
}

const User: React.FC<IUserProps> = ({ userId }) => {
	const [editUserData, setEditUserData] = useState<APIUser>();
	const [fetchedUser, setFetchedUser] = useState<APIUser>();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const fetchUserInfo = async () => {
		if (userId) {
			const response = await getAccountById(userId);
			if (response.body) {
				setEditUserData(response.body);
				setFetchedUser(response.body);
			}
		}
	};

	useEffect(() => {
		fetchUserInfo();
	}, []);

	const renderUsersData = () => (
		<>
			<p>
				<span>Email:</span> {fetchedUser?.email}
			</p>
			<p>
				<span>Discord:</span> {fetchedUser?.discord}
			</p>
			{fetchedUser?.skype && (
				<p>
					<span>Skype:</span> {fetchedUser?.skype}
				</p>
			)}
			{fetchedUser?.created && (
				<p>
					<span>Creation date:</span> {new Date(fetchedUser?.created).toLocaleString()}
				</p>
			)}
			{fetchedUser?.updated && (
				<p>
					<span>Last modified:</span> {new Date(fetchedUser?.updated).toLocaleString()}
				</p>
			)}
		</>
	);

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await handleChangesSave();
	};

	const renderEditForm = () => (
		<form onSubmit={handleFormSubmit}>
			<div className="input-group">
				<label>Email: </label>
				<input
					type="email"
					onChange={(e) => setEditUserData({ ...(editUserData as APIUser), email: e.target.value })}
					value={editUserData?.email}
				/>
			</div>
			<div className="input-group">
				<label>Discord: </label>
				<input
					pattern="\w+#\d{4}"
					title="Please input correct Discord username."
					type="text"
					onChange={(e) => setEditUserData({ ...(editUserData as APIUser), discord: e.target.value })}
					value={editUserData?.discord}
				/>
			</div>
			<div className="input-group">
				<label>Skype: </label>
				<input
					onChange={(e) => setEditUserData({ ...(editUserData as APIUser), skype: e.target.value })}
					value={editUserData?.skype}
				/>
			</div>
			<div className="user-details__operations">
				<Button type="submit" variant="green">
					Save changes
				</Button>
				<Button type="reset" variant="magenta" trigger={() => handleChangesRevert()}>
					Cancel changes
				</Button>
			</div>
		</form>
	);

	const handleChangesRevert = () => {
		setIsEditMode(false);
		setEditUserData(fetchedUser);
	};

	const handleChangesSave = async () => {
		try {
			await updateAccountById(userId!, {
				email: editUserData?.email,
				discord: editUserData?.discord,
				skype: editUserData?.skype,
			});
			setIsEditMode(false);
			setFetchedUser({ ...editUserData, updated: new Date().toString() } as APIUser);
			toast.success('Account update successful');
		} catch (err) {
			toast.error('Error during account update');
		}
	};

	return (
		<LayoutRoot>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<img className="services-cover__bamboo" src={bambooLeft} />
				<img className="services-cover__bamboo" src={bambooRight} />
				<div className="site-content container">
					<div className="services__header user-profile">
						<h1>WELCOME {fetchedUser?.username}</h1>
						<h3>THANKS FOR USING BAMBOOSTING.NET</h3>
					</div>
					<div className="user-details">
						<h3>
							{fetchedUser?.username}
							<span>{fetchedUser?.role}</span>
						</h3>
						<hr />
						{isEditMode ? renderEditForm() : renderUsersData()}
						{!isEditMode && (
							<Button variant="yellow" trigger={() => setIsEditMode(true)}>
								Edit
							</Button>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</LayoutRoot>
	);
};

export default User;
