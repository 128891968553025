import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finishOrder, getOrders, getOrdersAssignedToBooster } from '../../api/requests/requestHandler';
import { USER_ROLES } from '../../constants/general';
import { loadLoadingScreen, setPopup } from '../../redux/actions/generalActions';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import { APIOrder } from 'types/order';
import Spinner from 'components/shared/Spinner';
import BrandCover from 'components/shared/BrandCover';
import Footer from 'components/shared/Footer';
import OrdersList from '../panel/UI/OrdersList';
import LayoutRoot from 'components/shared/LayoutRoot';
import { toast } from 'react-toastify';
import Popup from 'components/shared/Popup';

interface IMyOrdersProps extends RouteComponentProps {}

const MyOrders: React.FC<IMyOrdersProps> = () => {
	const [orders, setOrders] = useState<APIOrder[]>();

	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading, isBackdropActive, popup } = useSelector((state: AppState) => state.general);

	const { role } = useSelector((state: AppState) => state.auth);

	const sortOrders = (orders: APIOrder[]) => {
		return orders.sort((a, b) => {
			if (new Date(a.createdAt) > new Date(b.createdAt)) {
				return -1;
			}
			return 1;
		});
	};

	useEffect(() => {
		thunkDispatch(loadLoadingScreen(true));
		if (role?.toLocaleUpperCase() === USER_ROLES.USER) {
			getOrders().then((res) => {
				setOrders(sortOrders(res.body!));
				thunkDispatch(loadLoadingScreen(false));
			});
		} else if (role?.toLocaleUpperCase() === USER_ROLES.BOOSTER) {
			getOrdersAssignedToBooster().then((res) => {
				setOrders(sortOrders(res.body!));
				thunkDispatch(loadLoadingScreen(false));
			});
		}
	}, []);

	const handlePopupClose = () => {
		thunkDispatch(setPopup(undefined));
	};

	const handlePopupAccept = async (id: string) => {
		document.body.style.cursor = 'wait';
		if (orders) {
			const ordersToUpdate = [...orders];
			const orderToUpdate = ordersToUpdate.find((o) => o.id === id);
			if (orderToUpdate) {
				orderToUpdate.status = 'DONE';
			}
			await finishOrder(id);
			thunkDispatch(setPopup(undefined));
			document.body.style.cursor = 'default';
			toast.success('Order successfully completed');
			setOrders(ordersToUpdate);
		}
	};

	const handlePersonalPanelButtonClick = async (id: string) => {
		thunkDispatch(
			setPopup({
				header: 'Warning!',
				content: 'Do you want to finish this order? \n Remember that this action is permament.',
				elementId: id,
			}),
		);
	};

	return (
		<LayoutRoot>
			<div className="orders-panel">
				<BrandCover filename="landing.jpg" />
				{isLoading ? (
					<Spinner />
				) : (
					<>
						<div className="orders-panel__filters container">
							<span>NUMBER</span>
							<span>TYPE</span>
							<span>SERVER</span>
							<span>PRICE</span>
							<span>STATUS</span>
							<span>DETAILS</span>
							<span>ACTIONS</span>
						</div>
						<OrdersList
							handlePersonalPanelButtonClick={(id: string) => handlePersonalPanelButtonClick(id)}
							isPersonal
							handleOrderPanelButtonClick={() => {}}
							data={orders}
						/>
					</>
				)}
				{popup && (
					<Popup
						header={popup.header}
						content={popup.content}
						onAccept={() => handlePopupAccept(popup.elementId)}
						onCancel={() => handlePopupClose()}
					/>
				)}
				{isLoading && !isBackdropActive && <Spinner isFull={true} />}
				<Footer />
			</div>
		</LayoutRoot>
	);
};

export default MyOrders;
