import React from 'react';
import clsx from 'clsx';
interface ICoachRankProps {
	isActive?: boolean;
	coachRank: string;
	rankImg: string;
	handleRankClick: () => void;
}

const CoachRank: React.FC<ICoachRankProps> = ({ isActive, coachRank, rankImg, handleRankClick }) => {
	return (
		<div onClick={() => handleRankClick()} className={clsx({ active: isActive }, 'coach-rank')}>
			<img src={rankImg} />
			<h3>{coachRank}</h3>
		</div>
	);
};

export default CoachRank;
