import React from 'react';

import { Link, RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IPrivacyProps extends RouteComponentProps {}

const Privacy: React.FC<IPrivacyProps> = () => {
	return (
		<LayoutRoot>
			<div className="legal legal--privacy-policy container">
				<h1>Privacy policy</h1>
				<p>
					Bamboosting.net understands that your privacy is important to you and that you care about how your personal
					data is used. We respect and value the privacy of everyone who visits this website,
					<Link to="/"> https://www.bamboosting.net/</Link>. Our Site will only collect and use personal data in ways
					that are described here, and in a way that is consistent with our obligations and your rights under the law.
				</p>
				<p>
					Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy
					Policy is deemed to occur upon your first use of Our Site. If you do not accept and agree with this Privacy
					Policy, you must stop using Our Site immediately.
				</p>
				<h3>1. Definitions and Interpretation</h3>
				<p>
					In this Policy the following terms shall have the following meanings: “Account” - means an account required to
					access and/or use certain areas and features of Our Site; “Cookie” - means a small text file placed on your
					computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features
					of Our Site. Details of the Cookies used by Our Site are set out in Part 14, below; and “Cookie Law” - means
					the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;
				</p>
				<h3>2. Information About Us</h3>
				<p>Our Site is owned and operated by Bamboosting.net Email address: info@bamboosting.net.</p>
				<h3>3. What Does This Policy Cover?</h3>
				<p>
					This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please
					note that we have no control over how your data is collected, stored, or used by other websites and we advise
					you to check the privacy policies of any such websites before providing any data to them.
				</p>
				<h3>4. What is Personal Data?</h3>
				<p>
					Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as
					‘any information relating to an identifiable person who can be directly or indirectly identified in particular
					by reference to an identifier’. Personal data is, in simpler terms, any information about you that enables you
					to be identified. Personal data covers obvious information such as your name and contact details, but it also
					covers less obvious information such as identification numbers, electronic location data, and other online
					identifiers.
				</p>
				<h3>5. What Are My Rights?</h3>
				<p>
					Under the GDPR, you have the following rights, which we will always work to uphold:
					<ol>
						<li>
							The right to be informed about our collection and use of your personal data. This Privacy Policy should
							tell you everything you need to know, but you can always contact us to find out more or to ask any
							questions using the details in Part 15.
						</li>
						<li>The right to access the personal data we hold about you. Part 13 will tell you how to do this.</li>
						<li>
							The right to have your personal data rectified if any of your personal data held by us is inaccurate or
							incomplete. Please contact us using the details in Part 15 to find out more.
						</li>
						<li>
							The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal
							data that we have. Please contact us using the details in Part 15 to find out more.
						</li>
						<li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
						<li>The right to object to us using your personal data for a particular purpose or purposes.</li>
						<li>
							The right to data portability. This means that, if you have provided personal data to us directly, we use
							it with your consent or for the performance of a contract, and that data is processed using automated
							means, you can ask us for a copy of that personal data to re-use with another service or business in many
							cases.
						</li>
						<li>
							Rights relating to automated decision-making and profiling. We do not use your personal data in this way.
						</li>
					</ol>
				</p>
				<p>
					For more information about our use of your personal data or exercising your rights as outlined above, please
					contact us using the details provided in Part 15. Further information about your rights can also be obtained
					from the Information Commissioner’s Office or your local Citizens Advice Bureau. If you have any cause for
					complaint about our use of your personal data, you have the right to lodge a complaint with the Information
					Commissioner’s Office.
				</p>
				<h3>What Data Do We Collect?</h3>
				<p>
					Depending upon your use of our Site, we may collect some or all of the following personal and non-personal
					data (please also see Part 14 on our use of Cookies and similar technologies and our{' '}
					<Link to="privacy-policy">Privacy Policy</Link>.
					<ul>
						<li>Name</li>
						<li>Email</li>
						<li>Payment information</li>
						<li>IP address</li>
						<li>Country</li>
						<li>Languages</li>
						<li>Information about „potential booster” related to the game League of Legends</li>
					</ul>
				</p>
				<h3>7. How Do You Use My Personal Data?</h3>
				<p>
					Under the GDPR, we must always have a lawful basis for using personal data. This may be because the data is
					necessary for our performance of a contract with you, because you have consented to our use of your personal
					data, or because it is in our legitimate business interests to use it. Your personal data may be used for one
					of the following purposes:
					<ul>
						<li>Providing and managing your Account</li>
						<li>Providing and managing your access to Our Site</li>
						<li>Personalising and tailoring your experience on Our Site</li>
						<li>
							Supplying our products services to you. Your personal details are required in order for us to enter into a
							contract with you.
						</li>
						<li>Personalising and tailoring our products and services for you.</li>
						<li>Communicating with you. This may include responding to emails or calls from you.</li>
						<li>Providing and managing the recruitment process for new boosters and coaches on our Site.</li>
					</ul>
				</p>
				<p>
					With your permission and where permitted by law, we may also use your personal data for marketing purposes,
					which may include contacting you by e-mail with information, news, and offers on our products and services.
					You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and
					comply with our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive)
					Regulations 2003, and you will always have the opportunity to opt-out.
				</p>
				<h3>8. How Long Will You Keep My Personal Data?</h3>
				<p>
					We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it
					was first collected. Your personal data will therefore be kept for the following periods:
					<ul>
						<li>
							Email address; this information will only be used for a possessing account on our website. The information
							will only be removed if the consumer doesn't log on his account after 5 years from his last activity on
							the account.
						</li>
						<li>
							Name; this information will only be used for sending payouts to our boosters, coaches and selling users on
							the website. This information will be kept in our database for a period of 2 years, after we proceed the
							last payment for the following person
						</li>
						<li>
							Payment information; the payment information will only be used for sending payouts to our boosters,
							coaches and selling users on the website. This information will be kept in our database for a period of 2
							years, after we proceed the last payment for the following person
						</li>
						<li>
							IP address; this information will only be used to provide the service or virtual goods to our consumers.
							This information will be removed 1 year right after the service is complete.
						</li>
						<li>
							Age; this specific information will only be used during the job application process. All job applications
							will be removed from our database 2 years after we receive the job application request.
						</li>
						<li>
							Country; this specific information will only be used during the job application process. All job
							applications will be removed from our database 2 years after we receive the job application request.
						</li>
						<li>
							Languages; this specific information will only be used during the job application process. All job
							applications will be removed from our database 2 years after we receive the job application request.
						</li>
						<li>
							Information about “potential booster” related to game League of Legends; this specific League of Legends
							related content will only be used during the job application process. All job applications will be removed
							from our database 2 years after we receive the job application request.
						</li>
					</ul>
				</p>
				<h3>9. How and Where Do You Store or Transfer My Personal Data?</h3>
				<p>
					We share your data with external third parties, as detailed below in Part 10, that are based outside of the
					EEA. The following safeguard[s] is applied to such transfers:
					<br />
					We will only store and transfer your personal data to countries that the European Commission has deemed to
					provide an adequate level of personal data protection. More information is available from the European
					Commission.
				</p>
				<h3>10. Do You Share My Personal Data?</h3>
				<p>
					We may sometimes contract with the following third parties to supply certain services. These may include
					payment processing, delivery, and marketing. In some cases, those third parties may require access to some or
					all of your personal data that we hold.
					<br />
					Microsoft Azure storing database of bamboosting.net Site. Microsoft Azure is located in USA If any of your
					personal data is required by a third party, as described above, we will take steps to ensure that your
					personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third
					party’s obligations under the law, as described above in Part 9.
					<br /> If any personal data is transferred outside of the EEA, we will take suitable steps in order to ensure
					that your personal data is treated just as safely and securely as it would be within the EEA countries and
					under the GDPR, as explained above in Part 9.
					<br />
					In some limited circumstances, we may be legally required to share certain personal data, which might include
					yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the
					instructions of a government authority.
				</p>
				<h3>11. How Can I Control My Personal Data?</h3>
				<ol>
					<li>
						In addition to your rights under the GDPR, set out in Part 5, when you submit personal data via Our Site,
						you may be given options to restrict our use of your personal data. In particular, we aim to give you strong
						controls on our use of your data for direct marketing purposes (including the ability to opt-out of
						receiving emails from us which you may do by unsubscribing using the links provided in our emails. The
						consent might be revoked at any time by electronic message or by clicking on the “Unsubscribe”link at the
						end of the commercial information.
					</li>
					<li>
						You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone
						Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing
						Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note,
						however, that these services will not prevent you from receiving marketing communications that you have
						consented to receiving.
					</li>
				</ol>
				<h3>12. Can I Withhold Information?</h3>
				<p>
					You may access our Site without providing any personal data at all. However, to use all features and functions
					available on Our Site you may be required to submit or allow for the collection of certain data. You may
					restrict our use of Cookies. For more information, see Part 14 and our{' '}
					<Link to="/privacy-policy">Privacy Policy</Link>.
				</p>
				<h3>13. How Can I Access My Personal Data?</h3>
				<p>
					If you want to know what personal data we have about you, you can ask us for details of that personal data and
					for a copy of it (where any such personal data is held). This is known as a “subject access request”.
					<br />
					All subject access requests should be made in writing and sent to the email or postal addresses shown in Part
					15. To make this as easy as possible for you, a Subject Access Request Form is available for you to use. You
					do not have to use this form, but it is the easiest way to tell us everything we need to know to respond to
					your request as quickly as possible.
					<br />
					There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or
					excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative
					costs in responding.
					<br />
					We will respond to your subject access request within 21 days and, in any case, not more than one month of
					receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within
					that time. In some cases, however, particularly if your request is more complex, more time may be required up
					to a maximum of three months from the date we receive your request. You will be kept fully informed of our
					progress.
				</p>
				<h3>14. How Do You Use Cookies?</h3>
				<p>
					Our Site may place and access certain first-party Cookies on your computer or device. First-party Cookies are
					those placed directly by us and are used only by us. We use Cookies to facilitate and improve your experience
					of Our Site and to provide and improve our services and products. By using Our Site you may also receive
					certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites,
					services, and/or parties other than us. Third-party Cookies are used on our Site for advertising and analytics
					services, which also use Cookies. Website analytics refers to a set of tools used to collect and analyze usage
					statistics, enabling us to better understand how people use Our Site. For more details, please refer to Our
					<Link to="/privacy-policy">Privacy Policy</Link>.
				</p>
				<h3>15. How Do I Contact You?</h3>
				<p>
					To contact us about anything to do with your personal data and data protection, including to make a subject
					access request, please use the following details:
					<br />
					Email address: info@bamboosting.net.
				</p>
				<h3>16. Changes to this Privacy Policy</h3>
				<p>
					We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes,
					or if we change our business in a way that affects personal data protection. <br /> Any changes will be
					immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your
					first use of Our Site following the alterations. We recommend that you check this page regularly to keep
					up-to-date.
				</p>
				<h3>Last updated: February 21, 2021</h3>
			</div>
		</LayoutRoot>
	);
};

export default Privacy;
