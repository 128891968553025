import React from 'react';
const amumu = require('../../../images/order-summary/amumu.png');

const Questions = () => (
	<div className="questions">
		<img src={amumu} alt="Sad Amumu :( " />
		<p>
			Questions?
			<br /> Talk to our support team!
		</p>
	</div>
);

export default Questions;
