import { ExtrasMultipliers } from '../types/general';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_EXTRAS_PRICING: RequestMetadata = {
	path: '/data/pricing/multipliers/pricing-extras.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetExtrasPricingResponseBody = ExtrasMultipliers;

export const getExtrasPricing = async (): Promise<RequestResponse<GetExtrasPricingResponseBody>> => {
	return await getRequest<undefined, GetExtrasPricingResponseBody>({ ...GET_EXTRAS_PRICING });
};

export default getExtrasPricing;
