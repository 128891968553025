import React from 'react';
interface INumericalInputProps {
	handleDecrementAction: () => void;
	handleIncrementAction: () => void;
	numericalActionToDispatch?: (number: number) => void;
	handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: number;
	variant?: string;
}

const NumericalInput: React.FC<INumericalInputProps> = ({
	handleDecrementAction,
	handleIncrementAction,
	value,
	numericalActionToDispatch,
	handleInputChange,
	variant,
}) => {
	return (
		<div className={`numerical-input ${variant}`}>
			<span
				onClick={() => {
					handleDecrementAction();
				}}
				className="material-icons numerical-input__icon"
			>
				remove
			</span>
			{numericalActionToDispatch !== undefined && (
				<input
					onChange={(e) => {
						numericalActionToDispatch(Number(e.target.value));
					}}
					value={value}
					type="number"
				/>
			)}
			{handleInputChange !== undefined && (
				<input
					onChange={(e) => {
						handleInputChange(e);
					}}
					value={value}
					type="number"
				/>
			)}

			<span
				onClick={() => {
					handleIncrementAction();
				}}
				className="material-icons numerical-input__icon"
			>
				add
			</span>
		</div>
	);
};

export default NumericalInput;
