import React from 'react';
import SEO from './SEO';
import Header from './Header';
import LoadingBackdrop from './LoadingBackdrop';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/root';
import { ToastContainer } from 'react-toastify';
import CookiesBar from 'components/shared/CookiesBar';
import 'react-toastify/dist/ReactToastify.css';

interface ILayoutRootProps {
	isMenuTransparent?: boolean;
	blockSEO?: boolean;
}

const LayoutRoot: React.FC<ILayoutRootProps> = ({ isMenuTransparent, children, blockSEO }) => {
	const { isBackdropActive, isLoading, isCookiesVisible } = useSelector((state: AppState) => state.general);

	return (
		<>
			{!blockSEO && <SEO />}
			<Header isMenuTransparent={isMenuTransparent} />
			{children}
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{isBackdropActive && isLoading && <LoadingBackdrop isMain />}
			{isCookiesVisible && <CookiesBar />}
		</>
	);
};

export default LayoutRoot;
