import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { APIOrder } from '../types/order';

const GET_ORDERS: RequestMetadata = {
	path: '/Boosters/orders',
	method: 'GET',
	headers: {},
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

type GetOrdersAssignedToResponseBody = APIOrder[];

export const getOrdersAssignedToBooster = async (): Promise<RequestResponse<GetOrdersAssignedToResponseBody>> => {
	return await getRequest<undefined, GetOrdersAssignedToResponseBody>(GET_ORDERS);
};

export default getOrdersAssignedToBooster;
