import { store } from '../redux/createStore';
import {
	API_BASE_URL,
	DATA_SERVER_URL,
	RequestMetadata,
	RequestParameters,
	RequestResponse,
	LOL_SERVER_URL,
	AUTH_HEADER,
} from './apiConfig';

type ErrorResponseBody = {
	message: string;
};

export async function getRequest<B, R = undefined>(
	requestMetadata: RequestMetadata,
	requestParameters: RequestParameters<B> | undefined = undefined,
): Promise<RequestResponse<R>> {
	const credentials = requestMetadata.credentials !== undefined ? 'include' : undefined;

	const res = await fetch(composeFullPath(requestMetadata, requestParameters), {
		method: requestMetadata.method,
		headers: getHeaders(requestMetadata),
		body: requestParameters && requestParameters.body ? JSON.stringify(requestParameters.body) : undefined,
		credentials: credentials,
	});
	const serverResponse = {
		body: requestMetadata.hasResponseBody ? await getBody<R>(res, requestMetadata) : undefined,
		headers: res.headers,
		statusCode: res.status,
	};
	if (serverResponse && serverResponse.body && !res.ok) {
		const errorResponse = (serverResponse.body as unknown) as ErrorResponseBody;
		throw Error(errorResponse.message);
	}
	return serverResponse;
}

async function getBody<R>(res: Response, requestMetadata: RequestMetadata): Promise<R> {
	try {
		if (requestMetadata.isFile) {
			return ((await res.blob()) as unknown) as R;
		}
		return (await res.json()) as R;
	} catch (err) {
		throw new Error('Parse error!');
	}
}

function getHeaders(requestMetadata: RequestMetadata) {
	const state = store.getState();
	if (requestMetadata.shouldAuth && state.auth.accessToken) {
		const authToken = state.auth.accessToken;
		requestMetadata.headers[AUTH_HEADER] = `Bearer ${authToken}`;
	}
	return requestMetadata.headers;
}

function composeFullPath<B>(requestMetadata: RequestMetadata, requestParameters: RequestParameters<B> | undefined) {
	let basePath: string = '';
	if (requestMetadata.isDataServer) {
		basePath = DATA_SERVER_URL + requestMetadata.path;
	} else if (requestMetadata.isLolApi) {
		basePath = LOL_SERVER_URL + requestMetadata.path;
	} else {
		basePath = API_BASE_URL + requestMetadata.path;
	}
	if (!requestParameters) {
		return basePath;
	}

	if (requestParameters.path) {
		requestParameters.path.forEach((value, key) => {
			basePath = basePath.replace(`{${key}}`, `${value}`);
		});
	}

	if (requestParameters.query) {
		const queryParams: string[] = [];
		requestParameters.query.forEach((value, key) => {
			queryParams.push(`${key}=${value}`);
		});
		basePath += '?' + queryParams.join('&');
	}

	return basePath;
}
