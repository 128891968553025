import React from 'react';
import { DATA_SERVER_URL } from '../../../api/apiConfig';
import Role from './Role';
import clsx from 'clsx';

interface IRolesPickerProps {
	isActive?: boolean;
	handleClick: () => void;
	handleRolesPickerClose: () => void;
}

const RolesPicker: React.FC<IRolesPickerProps> = ({ isActive, handleClick, handleRolesPickerClose }) => {
	const wrapperRef = React.createRef<HTMLDivElement>();

	return (
		<div ref={wrapperRef} className={clsx({ active: isActive }, 'roles-picker')}>
			<div className="roles-picker__header ">
				<span onClick={isActive ? handleRolesPickerClose : handleClick} className="material-icons">
					keyboard_arrow_up
				</span>
			</div>
			<div className="roles-picker__roles-list">
				<Role imgUrl={`${DATA_SERVER_URL}/assets/ranks/top.png`} name="Top" />
				<Role imgUrl={`${DATA_SERVER_URL}/assets/ranks/jungle.png`} name="Jungle" />
				<Role imgUrl={`${DATA_SERVER_URL}/assets/ranks/mid.png`} name="Mid" />
				<Role imgUrl={`${DATA_SERVER_URL}/assets/ranks/bot.png`} name="Adc" />
				<Role imgUrl={`${DATA_SERVER_URL}/assets/ranks/support.png`} name="Support" />
			</div>
		</div>
	);
};

export default RolesPicker;
