import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/root';

interface FinalPriceProps {
	totalPrice: number | undefined;
}

const FinalPrice: React.FC<FinalPriceProps> = ({ totalPrice }) => {
	const { basePrice } = useSelector((state: AppState) => state.payment);

	const fakePrice = totalPrice && Number(totalPrice * 1.6).toFixed(2);

	return (
		<>
			<span>Final Price</span>
			<h2>
				<span className="old-price">{basePrice !== undefined ? basePrice : fakePrice}€</span> {totalPrice}€
			</h2>
		</>
	);
};

export default FinalPrice;
