import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import Boosting from 'components/boosting/Boosting';
import Payment from 'components/payment';
import NotFoundPage from 'components/shared/404';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/root';
import OrdersPanel from 'components/panel/OrdersPanel';
import MyOrders from 'components/panel/MyOrders';
import User from 'components/user';
import { USER_ROLES } from '../constants/general';
import ResetPassword from 'components/reset-password';
import ForgotPassword from 'components/forgot-passsword';
import Activation from 'components/activation';
import Landing from 'components/landing';
import Cart from 'components/cart/Cart';
import Coaching from 'components/coaching';
import Faq from 'components/faq';
import Login from 'components/login';
import Register from 'components/register';
import Contact from 'components/contact';
import Boosters from 'components/boosters';
import TOU from 'components/tou';
import Privacy from 'components/privacy';

const App = () => {
	const { refreshToken, role } = useSelector((state: AppState) => state.auth);
	const [isClient, setClient] = useState(false);

	useEffect(() => {
		setClient(true);
	}, []);

	if (!isClient) {
		return null;
	}

	let routes = [
		<Landing path="/" key="landing" />,
		<Cart path="/cart" key="cart" />,
		<Coaching path="/coaching" key="coaching" />,
		<Faq path="/faq" key="faq" />,
		<ForgotPassword path="/forgot-password" key="forgot" />,
		<Login path="/login" key="login" />,
		<Register path="/register" key="register" />,
		<Contact path="/contact" key="contact" />,
		<Boosters path="/boosters" key="boosters" />,
		<TOU path="/terms-of-use" key="tou" />,
		<Privacy path="/privacy-policy" key="privacy" />,

		<ResetPassword path="/reset-password/:token" key="reset" />,
		<Activation path="/activation/:token" key="activation" />,
		<Boosting path="/boosting/:type" key="boosting" />,
		<Payment path="/payment/:orderId" key="payment" />,

		<NotFoundPage default key="404" />,
	];

	if (refreshToken !== undefined) {
		role?.toLocaleUpperCase() !== USER_ROLES.USER && routes.push(<OrdersPanel path="/orders-panel" key="panel" />);
		routes.push(<MyOrders path="/my-orders" key="my-orders" />);
		routes.push(<User path="/user/:userId" key="user" />);
	}

	return (
		<>
			<Router>{routes}</Router>
		</>
	);
};

export default App;
