import React from 'react';
import CoachingSummarySection from './UI/CoachingSummarySection';
import Footer from 'components/shared/Footer';
const background = require('src/images/global/champions_fight.jpg');
const bambooLeft = require('src/images/landing/bamboo-left.png');
const bambooRight = require('src/images/landing/bamboo-right.png');
import CoachingOrderCreation from './UI/CoachingOrderCreation';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface ICoachingProps extends RouteComponentProps {}

const Coaching: React.FC<ICoachingProps> = () => {
	return (
		<LayoutRoot isMenuTransparent>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<img className="services-cover__bamboo" src={bambooLeft} />
				<img className="services-cover__bamboo" src={bambooRight} />
				<div className="site-content container">
					<div className="services__header">
						<h1>COACHING</h1>
						<h3>Customize your coaching session</h3>
						<p>
							Are you hungry for knowledge and seeking for tips from the best? Create an order and spend time with one
							of our coaches teaching you how to improve as a player. Please select at least 1 role during order
							creation.
						</p>
					</div>
				</div>
				<CoachingOrderCreation />
				<CoachingSummarySection summaryImage={background} />
				<Footer />
			</div>
		</LayoutRoot>
	);
};

export default Coaching;
