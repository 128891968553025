import { RouteComponentProps } from '@reach/router';
import React from 'react';
import BrandCover from 'components/shared/BrandCover';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { useState } from 'react';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import { authForgotPassword } from '../../api/requests/requestHandler';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IForgotPasswordProps extends RouteComponentProps {}

type FormValues = {
	email: string;
};

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading } = useSelector((state: AppState) => state.general);
	const { register, handleSubmit, errors, formState, watch } = useForm({ mode: 'onChange' });
	const [message, setMessage] = useState<string>();

	const onSubmit = (data: FormValues) => {
		thunkDispatch(loadLoadingScreen(true));
		authForgotPassword(data.email).then((res) => {
			setMessage(res.body?.message);
			thunkDispatch(loadLoadingScreen(false));
		});
	};

	const renderFormBody = () => (
		<>
			<h3>Forgot password form</h3>
			<label>
				E-MAIL
				{errors.email && <span> {errors.email.message}</span>}
			</label>
			<input
				name="email"
				ref={register({
					required: 'Please specify email.',
					pattern: {
						value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						message: 'Invalid email.',
					},
				})}
				required
				type="email"
				className="login-input"
				placeholder="E-mail"
			/>
			<Button type="submit" variant="green">
				Submit
			</Button>
		</>
	);

	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" isFull>
				<form className="password-form" onSubmit={handleSubmit(onSubmit)}>
					{!message && (isLoading ? <Spinner /> : renderFormBody())}
					{message !== undefined && <h1>{message}</h1>}
				</form>
			</BrandCover>
		</LayoutRoot>
	);
};

export default ForgotPassword;
