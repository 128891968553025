import React, { useState, useEffect } from 'react';
import Footer from 'components/shared/Footer';
const background = require('src/images/services/services-background.jpg');
import { Booster, Boosters as BoostersModel } from 'types/general';
import { getBoosters } from '../../api/requests/requestHandler';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';
import { BoostersTileList, BoosterDropdown } from './UI';
interface IBoostersProps extends RouteComponentProps {}

interface DynamicObject<T> {
	[key: string]: T;
}

interface BoosterFilterValues extends DynamicObject<string> {
	league: string;
	role: string;
	server: string;
	language: string;
}

const Boosters: React.FC<IBoostersProps> = () => {
	const leagues = ['Diamond', 'Master', 'Grandmaster', 'Challenger'];
	const languages = ['English', 'German', 'Polish'];
	const roles = ['Top', 'Jungle', 'Mid', 'Adc', 'Support'];
	const servers = ['EUNE', 'TUR', 'RUS', 'EUW'];
	const [filtersValues, setFiltersValues] = useState<BoosterFilterValues>({
		league: 'All',
		role: 'All',
		server: 'All',
		language: 'All',
	});
	const [boosters, setBoosters] = useState<BoostersModel>();
	const [fetchedBoosters, setFetchedBoosters] = useState<BoostersModel>();
	const languagesRecords: Record<string, string> = {
		Polish: 'pl',
		English: 'gb',
		German: 'de',
	};

	abstract class Filter {
		abstract filterType: string;
		abstract filter(boosters: Booster[], filtersValues: BoosterFilterValues): Booster[];
	}

	class ServerFilter extends Filter {
		constructor() {
			super();
			this.filterType = 'server';
		}
		filterType: string;

		filter(boosters: Booster[], filtersValues: BoosterFilterValues) {
			if (filtersValues[this.filterType] === 'All') {
				return boosters;
			}
			return boosters.filter((booster) => {
				const servers = booster.servers.map((server) => server.name.toLocaleLowerCase());
				return servers.includes(filtersValues.server.toLocaleLowerCase());
			});
		}
	}

	class RoleFilter extends Filter {
		constructor() {
			super();
			this.filterType = 'role';
		}
		filterType: string;

		filter(boosters: Booster[], filtersValues: BoosterFilterValues) {
			if (filtersValues[this.filterType] === 'All') {
				return boosters;
			}
			return boosters.filter((booster) => {
				const roles = booster.roles.map((role) => role.name.toLocaleLowerCase());
				return roles.includes(filtersValues.role.toLocaleLowerCase());
			});
		}
	}

	class LeagueFilter extends Filter {
		constructor() {
			super();
			this.filterType = 'league';
		}
		filterType: string;

		filter(boosters: Booster[], filtersValues: BoosterFilterValues) {
			if (filtersValues[this.filterType] === 'All') {
				return boosters;
			}
			return boosters.filter((booster) => {
				return booster.league.name.toLocaleLowerCase() === filtersValues.league.toLocaleLowerCase();
			});
		}
	}

	class LanguageFilter extends Filter {
		constructor() {
			super();
			this.filterType = 'language';
		}
		filterType: string;

		filter(boosters: Booster[], filtersValues: BoosterFilterValues) {
			if (filtersValues[this.filterType] === 'All') {
				return boosters;
			}
			return boosters.filter((booster) => {
				const languages = booster.countryCodes.map((country) => country.toLocaleLowerCase());
				const code = languagesRecords[filtersValues.language];
				return languages.includes(code.toLocaleLowerCase());
			});
		}
	}

	const handleFilterSelection = (property: string, value: string) => {
		if (fetchedBoosters) {
			let filters = { ...filtersValues };
			filters[property] = value;
			setFiltersValues(filters);

			const boostersToUpdate = { ...fetchedBoosters };
			const serverFilter = new ServerFilter();
			const leagueFilter = new LeagueFilter();
			const roleFilter = new RoleFilter();
			const languageFilter = new LanguageFilter();
			const filteredByLeague = leagueFilter.filter(boostersToUpdate.boosters, filters);
			const filteredByServer = serverFilter.filter(boostersToUpdate.boosters, filters);
			const filteredByRole = roleFilter.filter(boostersToUpdate.boosters, filters);
			const filteredByLanguage = languageFilter.filter(boostersToUpdate.boosters, filters);
			const filteredBoosters = fetchedBoosters.boosters.filter(
				(booster) =>
					filteredByLeague.includes(booster) &&
					filteredByServer.includes(booster) &&
					filteredByRole.includes(booster) &&
					filteredByLanguage.includes(booster),
			);
			setBoosters({ boosters: filteredBoosters });
		}
	};

	useEffect(() => {
		getBoosters().then((res) => {
			setFetchedBoosters(res.body);
			setBoosters(res.body);
		});
	}, []);

	return (
		<LayoutRoot isMenuTransparent>
			<div style={{ backgroundImage: `url(${background})` }} className="services-cover">
				<div className="site-content container boosters">
					<div className="services__header">
						<h1>Boosters</h1>
						<h3>
							Browse through the list of our experienced <br /> boosters from all around the world.
						</h3>
					</div>
					<h2>Filter by</h2>
					<div className="boosters__filters">
						<BoosterDropdown
							handleFilterSelection={(value: string) => handleFilterSelection('server', value)}
							label="Server"
							options={servers}
						/>
						{languages !== undefined && (
							<BoosterDropdown
								handleFilterSelection={(value: string) => handleFilterSelection('language', value)}
								label="Language"
								options={languages}
							/>
						)}

						<BoosterDropdown
							handleFilterSelection={(value: string) => handleFilterSelection('league', value)}
							label="League"
							options={leagues}
						/>
						<BoosterDropdown
							handleFilterSelection={(value: string) => handleFilterSelection('role', value)}
							label="Role"
							options={roles}
						/>
					</div>
					{boosters && <BoostersTileList boostersList={boosters} />}
				</div>
			</div>
			<Footer />
		</LayoutRoot>
	);
};

export default Boosters;
