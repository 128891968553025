import { ForgotPasswordRequestBody, ForgotPasswordResponseBody } from '../types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_FORGOT_PASSWORD: RequestMetadata = {
	path: '/Accounts/forgot-password',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authForgotPassword = async (email: string): Promise<RequestResponse<ForgotPasswordResponseBody>> => {
	return await getRequest<ForgotPasswordRequestBody, ForgotPasswordResponseBody>(AUTH_FORGOT_PASSWORD, {
		body: {
			email: email,
		},
	});
};

export default authForgotPassword;
