import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const ASSIGN_ORDER: RequestMetadata = {
	path: '/Boosters/orders/{orderId}/assign',
	method: 'PUT',
	headers: {},
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

export const assignBoosterToOrder = async (orderId: string): Promise<RequestResponse<undefined>> => {
	return await getRequest<undefined, undefined>(ASSIGN_ORDER, {
		path: new Map<string, string>([['orderId', `${orderId}`]]),
	});
};

export default assignBoosterToOrder;
