import React, { useState } from 'react';
import BrandCover from 'components/shared/BrandCover';
import LoginInput from './LoginInput';
import Button from 'components/shared/Button';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, ThunkGlobalDispatch } from '../../redux/root';
import { dispatchLogin } from '../../redux/actions/authActions';
import Spinner from 'components/shared/Spinner';
import { toast } from 'react-toastify';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface ILoginProps extends RouteComponentProps {}

const Login: React.FC<ILoginProps> = () => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { username, role } = useSelector((state: AppState) => state.auth);
	const { isLoading } = useSelector((state: AppState) => state.general);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			await thunkDispatch(dispatchLogin({ email: email, password: password }, isChecked));
			toast.success('Login succesful.');
		} catch (err) {
			toast.error('Email or password is incorrect');
			thunkDispatch(loadLoadingScreen(false));
		}
	};

	const redirectBasedOnRole = () => {
		setTimeout(() => {
			navigate('/my-orders');
		}, 3000);
	};

	const renderFormBody = () => {
		if (!username) {
			return (
				<>
					<label>E-MAIL</label>
					<LoginInput isEmail isRequired handleInputChange={(value: string) => setEmail(value)} inputText="Email" />
					<label>PASSWORD</label>
					<div className="pass-wrapper">
						<LoginInput
							isRequired
							handleInputChange={(value: string) => setPassword(value)}
							isPassword={!showPassword}
							inputText="Password"
						/>
						<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
							{showPassword ? 'visibility_off' : 'visibility'}
						</span>
					</div>
					<label className="input-container">
						Remember me
						<input checked={isChecked} onChange={() => setIsChecked(!isChecked)} type="checkbox" />
						<span className="checkmark"></span>
						<Link to="/forgot-password">Forgot password?</Link>
					</label>
					<Button type="submit" variant="green">
						Log In
					</Button>
				</>
			);
		}
		return (
			<h2 className="logged-in-msg">
				<span>Logged in.</span> Redirecting...
				{redirectBasedOnRole()}
			</h2>
		);
	};

	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" isFull>
				<form className="login-form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleLoginSubmit(e)}>
					{isLoading ? <Spinner /> : renderFormBody()}
				</form>
			</BrandCover>
		</LayoutRoot>
	);
};

export default Login;
