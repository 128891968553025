import React from 'react';
import BoostingType from '../BoostingTypes/BoostingType';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/root';

const TypesBar: React.FC = () => {
	const { orderType } = useSelector((state: AppState) => state.payment);

	return (
		<div className="container types-bar">
			<BoostingType isActive={orderType === 'league'} boostingTypeName="League Boosting" pathToBoostingType="league" />
			<BoostingType isActive={orderType === 'duo'} boostingTypeName="Duo Boosting" pathToBoostingType="duo" />
			<BoostingType isActive={orderType === 'netwins'} boostingTypeName="Win Boosting" pathToBoostingType="netwins" />
			<BoostingType
				isActive={orderType === 'placement'}
				boostingTypeName="Placement Matches"
				pathToBoostingType="placement"
			/>
		</div>
	);
};

export default TypesBar;
